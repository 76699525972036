import React, { useEffect, useState, useRef  } from 'react';
import { useParams } from 'react-router-dom';
import roleIcon from './data/Role'; // Adjust the path accordingly
import './Player.css';
import { championData, championPhotos } from "./data/ChampionsIcon";
import 'react-pivottable/pivottable.css';
import { TeamIcons } from './data/TeamIcons';
import 'rc-slider/assets/index.css';
import { useSeason } from './SeasonContext';
import mapPhotsDictionary from './data/MapData';
import axiosInstance from './backend/axiosInstance';
import Select from 'react-select';

function renderChampions(game, firstChampion, secondChampion, thirdChampion, fourthChampion, fifthChampion, isBlueTeam) {
    const teamOrderDict = isBlueTeam ? {
        [firstChampion]: "BP1",
        [secondChampion]: "BP2",
        [thirdChampion]: "BP3",
        [fourthChampion]: "BP4",
        [fifthChampion]: "BP5",
    } : {
        [firstChampion]: "RP1",
        [secondChampion]: "RP2",
        [thirdChampion]: "RP3",
        [fourthChampion]: "RP4",
        [fifthChampion]: "RP5",
    };

    return [0, 1, 2, 3, 4].map(index => {
        const championKey = teamOrderDict[index];
        return (
            <div key={index} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: isBlueTeam ? '0 5px 0 0' : '0 0 0 5px' }}>
                <img src={roleIcon[index]} alt={""} style={{ width: "15px" }} />
                <img
                    src={championPhotos[championData[game[championKey]]]}
                    alt={""}
                    className="small-image"
                />
            </div>
        );
    });
}

const Level1GameMap = ({ gameId, matchData, teamName, timeRange, recallData  }) => {
    const [playerPositionData, setPlayerPositionData] = useState([]);
    const [wardData, setWardData] = useState(null); // New state for ward data
    const [jungleCampData, setJungleCampData] = useState([]);

    const mapWidth = 500;
    const mapHeight = 500;
    const minX = -120;
    const minY = -120;
    const maxX = 14970;
    const maxY = 14980;
    const translateCoordinates = (x, y) => {
      const newX = ((x - minX) / (maxX - minX)) * mapWidth;
      const newY = ((maxY - y) / (maxY - minY)) * mapHeight;
      return { newX, newY };
    };


    const getColorBasedOnTime = (time, side) => {
        const intensity = Math.min(255, Math.max(0, Math.floor(((time - timeRange[0]) / timeRange[1]) * 255))); // Ensure intensity is within 0-255
        if (side === "red") {
            return `rgb(255, ${255 - intensity}, ${255 - intensity})`; // Red fades from white to full red
        } else if (side === "blue") {
            return `rgb(${255 - intensity}, ${255 - intensity}, 255)`; // Blue fades from white to full blue
        }
        return `rgb(${255 - intensity}, ${255 - intensity}, ${255 - intensity})`; // Default to white-to-gray if side is unknown
    };

    useEffect(() => {

        axiosInstance.get(`playerposition/?playername=${matchData.summonerName}&gameId=${gameId}&start=${timeRange[0]}&end=${timeRange[1]}`)
          .then(responses => {
            setPlayerPositionData(responses.data);
          })
          .catch(error => console.error("Error fetching player data:", error));

          axiosInstance.get(`playerwards_official/?gameId=${gameId}&short=1`)
          .then(response => {
              setWardData(response.data);
          })
          .catch(error => {
              console.error('Error fetching ward data:', error);
          });


          axiosInstance.get(`jungleCamps/?gameId=${gameId}&playername=${matchData.summonerName}&start=${timeRange[0]}&end=${timeRange[1]}`)
          .then(response => {
            setJungleCampData(response.data); // Correct state update
          })
          .catch(error => {
              console.error('Error fetching ward data:', error);
          });
      }, [gameId]);


        return (
        <div style={{display: 'flex', flexDirection: "column", width: "100%", padding: "20px"}}>
            <div style={{display: 'flex'}}>
                <div  style={{display: 'flex', position: "relative"}}>
                <img
                src={mapPhotsDictionary["SummonersRift"]}
                alt="Summoner’s Rift"
                style={{ width: "500px", height: "500px" }}
                />
                    {wardData && wardData.filter((ward) => {return ward.placeTime <= timeRange[1]}).map((position, index) => {
                        const { newX, newY } = translateCoordinates(position.x, position.y, 500);
                        return (
                            <div
                                key={index}
                                style={{
                                position: 'absolute',
                                top: newY-5 + 'px',
                                left: newX-5 + 'px',
                                width: '10px',
                                height: '10px',
                                backgroundColor: position.side,
                                borderRadius: '50%',
                                color: 'white'
                                }}
                            >
                                {Math.floor(position.placeTime / 60)}:{String(position.placeTime % 60).padStart(2, '0')}
                            </div>
                        );
                        }
                    )}
                    {playerPositionData && (
                        <div
                            key='timerr'
                            style={{
                            position: 'absolute',
                            top:  0,
                            left: "70%",
                            color: 'red',
                            fontWeight: teamName===matchData.team2 ? "bold" : "normal",
                            }}>
                            <img src={TeamIcons[matchData.team2]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                            {matchData.team2}
                        </div>
                    )}
                    {playerPositionData && (
                        <div
                            key='timerr'
                            style={{
                            position: 'absolute',
                            top:  0,
                            left: "20%",
                            color: 'blue',
                            fontWeight: teamName===matchData.team1 ? "bold" : "normal",

                            }}>
                            <img src={TeamIcons[matchData.team1]} alt="" style={{widt: "20px", height:"20px", objectFit: 'contain'}} />
                            {matchData.team1}
                        </div>
                    )}
                    {playerPositionData && playerPositionData.map((position, index) => {
                        const { newX, newY } = translateCoordinates(position.x, position.y, 500);
                        const color = getColorBasedOnTime(position.time, position.side);

                        return (
                            <div
                            key={`player-${index}--t`}
                            style={{
                                position: 'absolute',
                                top: `${newY - 3}px`,
                                left: `${newX - 3}px`,
                                width: '6px',
                                height: '6px',
                                backgroundColor: color,
                                borderRadius: '50%',
                            }}
                            >
                            </div>
                        );
                        }
                    )}
                    {playerPositionData && playerPositionData.filter((ward) => {return ward.time == timeRange[1] }).map((position, index) => {
                    const { newX, newY } = translateCoordinates(position.x, position.y, 500);
                    return (
                        <div
                        key={`player-${index}--t`}
                        style={{
                            position: 'absolute',
                            top: `${newY - 13}px`,
                            left: `${newX - 13}px`,
                            width: '26px',
                            height: '26px',
                            backgroundColor: position.side === (teamName===matchData.team1 ? "blue":"red") ? "purple" : 'transparent',
                            borderRadius: '50%',
                        }}
                        >

                            <div
                                key={`player-${index}-`}
                                style={{
                                    position: 'absolute',
                                    top: `2px`,
                                    left: `2px`,
                                    width: '22px',
                                    height: '22px',
                                    backgroundColor: position.side,
                                    borderRadius: '50%',
                                }}
                                >

                                <img
                                    src={championPhotos[position.championName]}
                                    alt=""
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        position: 'absolute',
                                        borderRadius: '50%',
                                        top: '1px',
                                        left: '1px',
                                    }}
                                    />
                            </div>
                        </div>
                    );
                    }
                    )}

                    {jungleCampData && jungleCampData.sort((a, b) =>(a.killTime) -(b.killTime)).map((camp, index) => {
                        const { newX, newY } = translateCoordinates(camp.x, camp.y, 500);
                        return (
                            <div
                                key={index}
                                style={{
                                position: 'absolute',
                                top: newY-25 + 'px',
                                left: newX-5 + 'px',
                                fontWeight: "bold",
                                width: '10px',
                                height: '10px',
                                borderRadius: '50%',
                                color: 'yellow'
                                }}
                            >
                                {`${index + 1}`}
                            </div>
                        );
                        }
                    )}
                </div>
                <div style={{width: "100%", display: 'flex', flexDirection: "column", justifyContent: 'space-between'}}>

                    <table style={{color: 'white'}}>
                        <thead>
                            <tr>
                                <th style={{textAlign: "center"}}>idx</th>
                                <th>Monster</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                        {jungleCampData && jungleCampData.sort((a, b) =>(a.killTime) -(b.killTime)).map((camp, index) => {
                            console.log(camp);
                            return (
                                <tr>
                                    <td style={{textAlign: "center"}}>{index+1}</td>
                                    <td>{camp.monsterType}</td>
                                    <td>{Math.floor(camp.killTime / 60)}:{String(camp.killTime % 60).padStart(2, '0')}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    Recall
                    <table style={{color: 'white'}}>
                        <thead>
                            <tr>
                                <th>Time</th>
                                <th>Type</th>
                                <th>Pocket</th>
                                <th>GD</th>
                                <th>XPD</th>
                                <th>KDA</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{Math.floor(timeRange[1] / 60)}:{String(timeRange[1] % 60).padStart(2, '0')}</td>
                                <td>{recallData.type}</td>
                                <td>{recallData.currentGold}</td>
                                <td>{recallData.goldDiff}</td>
                                <td>{recallData.expDiff}</td>

                                <td>{recallData.kills}/{recallData.deaths}/{recallData.assists}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        {playerPositionData && playerPositionData.length > 0 ? (
                            <div>
                                <div style={{
                                    height: '20px',
                                    width: `100%`, // Example width calculation
                                    display: 'inline-block',
                                    background: `linear-gradient(to right,
                                    ${getColorBasedOnTime(timeRange[0], playerPositionData[0].side)} 0%,
                                    ${getColorBasedOnTime((timeRange[0]+timeRange[1])/2, playerPositionData[0].side)} 50%,
                                    ${getColorBasedOnTime(timeRange[1], playerPositionData[0].side)} 100%)`,
                                }}/>
                                <div style={{ color: 'white', display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        Start: {Math.floor(timeRange[0] / 60)}:{String(timeRange[0] % 60).padStart(2, '0')}
                                    </div>
                                    <div>
                                        End: {Math.floor(timeRange[1] / 60)}:{String(timeRange[1] % 60).padStart(2, '0')}
                                    </div>

                                </div>
                            </div>
                            ):(
                            <>
                            A
                            </>
                        )}
                    </div>

                </div>
            </div>
            <div style={{display: 'flex', backgroundColor: "white", justifyContent: 'center'}}>
                {renderChampions(matchData, matchData.B1R, matchData.B2R, matchData.B3R, matchData.B4R, matchData.B5R, true)}
                <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
                    <img className="small-image" src={TeamIcons[matchData.team1]} alt={matchData.team1} style={{ width: '25px', marginRight: '5px' }} />
                    {matchData.team1} vs {matchData.team2}
                    <img className="small-image" src={TeamIcons[matchData.team2]} alt={matchData.team2} style={{ width: '25px', marginLeft: '5px' }} />
                </div>
                {renderChampions(matchData, matchData.R1R, matchData.R2R, matchData.R3R, matchData.R4R, matchData.R5R, false)}
            </div>
        </div>
    );
  };

  const JungleCampComponent = () => {
    const { currentSeasonTime } = useSeason();
    const { teamName } = useParams();
    document.title = "Jungle Pathing " + teamName;

    const [matchData, setMatchData] = useState([]);
    const [recallData, setRecallData] = useState({});
    const [loadingRecall, setLoadingRecall] = useState(false);

    const [selectedTeamSide, setSelectedTeamSide] = useState({ value: "any", label: 'Any' });
    const [filterTeamSide, setFilterTeamSide] = useState(["any"]);

    const teamSideOptions = [
      { value: "any", label: 'Any' },
      { value: "team1", label: 'Blue' },
      { value: "team2", label: 'Red' },
    ];

    const handleTeamSideChange = (selectedOption) => {
      setFilterTeamSide([selectedOption.value]);
      setSelectedTeamSide(selectedOption);
    };

    useEffect(() => {
      const [start, end] = currentSeasonTime.split(" - ").map((date) => date.trim());
      axiosInstance
        .get('junglers_for_team/?start=' + start + "&end=" + end + '&team_name=' + teamName)
        .then((response) => {
          const sortedMatchData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
          setMatchData(sortedMatchData);

          setLoadingRecall(true);
          const recallPromises = sortedMatchData.map((game) =>
            axiosInstance
              .get(`recall/?gameId=${game.gameId}&playername=${encodeURIComponent(game.summonerName)}`)
              .then((res) => ({ gameId: game.gameId, recalls: res.data }))
              .catch((err) => ({ gameId: game.gameId, error: err }))
          );

          Promise.all(recallPromises).then((results) => {
            const recallMap = results.reduce((acc, result) => {
              acc[result.gameId] = result.recalls || [];
              return acc;
            }, {});
            setRecallData(recallMap);
            setLoadingRecall(false);
          });
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }, [currentSeasonTime, teamName]);

    const filteredMatchData = matchData.filter((game) => {
      if (filterTeamSide.includes("any")) return true; // No filtering if "any" is selected
      if (filterTeamSide.includes("team1") && game.team1 === teamName) return true; // Blue side
      if (filterTeamSide.includes("team2") && game.team2 === teamName) return true; // Red side
      return false;
    });

    return (
      <div>
        <div className="filters">
          Side
          <div style={{ padding: "10px", color: "black" }}>
            <Select
              value={selectedTeamSide}
              onChange={handleTeamSideChange}
              options={teamSideOptions}
              style={{ color: "black" }}
            />
          </div>
        </div>
        <div style={{ width: "100%", textAlign: "center", color: "white" }}>
          Jungle Pathing
        </div>
        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between" }}>
          {filteredMatchData.map((game, idx) => {
            const recalls = recallData[game.gameId] || [];
            return (
              <div key={game.gameId} style={{ width: "100%", display: 'flex' }}>
                {recalls.length > 0 && !loadingRecall ? (
                  <>
                    <div key={`${game.gameId}-first`} style={{ width: "50%" }}>
                      <Level1GameMap
                        gameId={game.gameId}
                        matchData={matchData[idx]}
                        teamName={teamName}
                        timeRange={[90, recalls[0]?.time || 240]} // First map: 1:30 to first recall
                        recallData={recalls[0]}
                      />
                    </div>

                    <div key={`${game.gameId}-second`} style={{ width: "50%" }}>
                      <Level1GameMap
                        gameId={game.gameId}
                        matchData={matchData[idx]}
                        teamName={teamName}
                        timeRange={[recalls[0]?.time || 240, recalls[1]?.time || 240]} // Second map: First recall to second recall
                        recallData={recalls[1]}
                      />
                    </div>
                  </>
                ) : (
                  <div />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

export default JungleCampComponent;
