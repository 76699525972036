import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { championData, championPhotos } from './data/ChampionsIcon';
import './TierlistForm.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import roleIcon from './data/Role';
import { classesPhotos } from './data/ClassesIcon';
import axiosInstance from './backend/axiosInstance';
import axiosInstanceMongo from './backend/axiosInstanceMongo';
const rolesPublic = ['top', 'jgl', 'mid', 'bot', 'sup'];

const sortChampionIds = (championIds) => {
  return championIds.sort((a, b) => {
    const nameA = championData[a]?.toLowerCase();
    const nameB = championData[b]?.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
};

const initialTiers = {
  top: {
    S: [],
    A: [],
    B: [],
    C: [],
    D: [],
    pool: sortChampionIds(Object.keys(championData)),
  },
  jgl: {
    S: [],
    A: [],
    B: [],
    C: [],
    D: [],
    pool: sortChampionIds(Object.keys(championData)),
  },
  mid: {
    S: [],
    A: [],
    B: [],
    C: [],
    D: [],
    pool: sortChampionIds(Object.keys(championData)),
  },
  bot: {
    S: [],
    A: [],
    B: [],
    C: [],
    D: [],
    pool: sortChampionIds(Object.keys(championData)),
  },
  sup: {
    S: [],
    A: [],
    B: [],
    C: [],
    D: [],
    pool: sortChampionIds(Object.keys(championData)),
  },
};

const tierColors = {
  S: 'rgb(255, 127, 128)',
  A: 'rgb(255, 192, 127)',
  B: 'rgb(255, 255, 127)',
  C: 'rgb(191, 255, 127)',
  D: 'rgb(127, 255, 127)',
};

const TierlistForm = () => {
  document.title = "Tierlist";
  const [tiers, setTiers] = useState(initialTiers);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentRole, setCurrentRole] = useState('top');
  const [championTags, setChampionTags] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  const [classes, setClasses] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showCombined, setShowCombined] = useState(false);

  const toggleCombinedView = () => {
    setShowCombined(!showCombined);
  };
  useEffect(() => {
    const team = localStorage.getItem('team');

    axiosInstanceMongo.get(`tierlist/${team}`)
      .then(response => {
        if (response.data) {
          setTiers(response.data.tiers);
        }
      })
      .catch(error => {
        console.error('Error loading tier list:', error);
      });

      axiosInstance.get('champion_roles/')
      .then(response => {
        const { columns, data } = response.data;

        if (Array.isArray(columns) && Array.isArray(data)) {
          const classNames = columns;
          const championClassMap = {};

          data.forEach(({ championId, className }) => {
            if (!championClassMap[championId]) {
              championClassMap[championId] = [];
            }
            championClassMap[championId].push(className);
          });

          setChampionTags(championClassMap);
          setClasses(classNames);
        } else {
          console.error('Unexpected response structure:', response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching roles and classes:', error);
      });

    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        saveTierList();
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!hasUnsavedChanges) return;

    const interval = setInterval(() => {
      saveTierList();
    }, 5000); // Save every 5 seconds

    return () => clearInterval(interval);
  }, [hasUnsavedChanges]);

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;

    const sourceRole = source.droppableId.split('-')[0];
    const sourceTier = source.droppableId.split('-')[1];
    const destinationRole = destination.droppableId.split('-')[0];
    const destinationTier = destination.droppableId.split('-')[1];

    const updatedTiers = JSON.parse(JSON.stringify(tiers));

    const sourceIndex = updatedTiers[sourceRole][sourceTier].indexOf(draggableId);
    if (sourceIndex !== -1) {
      updatedTiers[sourceRole][sourceTier].splice(sourceIndex, 1);
    }

    updatedTiers[destinationRole][destinationTier].splice(destination.index, 0, draggableId);

    const allTiers = ['S', 'A', 'B', 'C', 'D'];
    updatedTiers[sourceRole].pool = sortChampionIds(
      Object.keys(championData).filter(champId =>
        !allTiers.some(tier => updatedTiers[sourceRole][tier].includes(champId))
      )
    );
    updatedTiers[destinationRole].pool = sortChampionIds(
      Object.keys(championData).filter(champId =>
        !allTiers.some(tier => updatedTiers[destinationRole][tier].includes(champId))
      )
    );

    setTiers(updatedTiers);
    setHasUnsavedChanges(true); // Mark changes as unsaved
  };

  const saveTierList = () => {
    const tierListData = {
      teamName: localStorage.getItem('team'),
      tiers: tiers,
    };

    axiosInstanceMongo.post('tierlist/', tierListData)
      .then((response) => {
        toast.success('Tierlist updated successfully', {duration: 2000});
        setHasUnsavedChanges(false); // Mark changes as saved
      })
      .catch((error) => {
        toast.error('Error updating tierlist. Please try again.');
      });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRoleChange = (role) => {
    setCurrentRole(role);
  };

  const handleTagSelection = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter(t => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleScoutingClick = () => {
    window.location.href = `/ChampionRole`;
  };

  return (
    <div>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <div className="role-selector">
        {rolesPublic.map((role, index) => (
        <button
          key={role}
          onClick={() => handleRoleChange(role)}
          style={{ width: "20%" }}
          className={currentRole === role ? 'button-top-page-active' :'button-top-page'}
        >
          <img className="small-image" src={roleIcon[index]} alt={role}/>
          {role.toUpperCase()}
        </button>
        ))}

      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="tiers-container">
        {showCombined ? (
        // Render all roles in a single view
        <div style={{display:'flex'}}>
          {rolesPublic.map((role) => (
            <div key={role} style={{width: "20%"}}>
              <h2 style={{ color: "white", textAlign: "center" }}>{role.toUpperCase()}</h2>
              <div className="tiers-container">
                {Object.keys(tiers[role]).map((tier) =>
                  tier !== "pool" && (
                    <Tier key={`${role}-${tier}`} role={role} tier={tier} items={tiers[role][tier]} showHeader={false} />
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        // Render only the selected role
        <div className="tiers-container">
          {Object.keys(tiers[currentRole]).map((tier) =>
            tier !== "pool" && <Tier key={tier} role={currentRole} tier={tier} items={tiers[currentRole][tier]} showHeader={true}/>
          )}
        </div>
      )}
        </div>
        <div style={{color:"white", width: "100%", display: 'flex', justifyContent: 'space-around'}}>
          <div style={{display: 'flex', width: "20%", flexDirection: 'column', justifyContent: 'space-around'}}>
            <input
              type="text"
              placeholder="Filter champion name"
              value={searchTerm}
              onChange={handleSearchChange}
              />
            <button className='button-top-page' onClick={() => handleScoutingClick()}>Change Roles</button>
            <button className="button-top-page" onClick={toggleCombinedView}>
              {showCombined ? "Single" : "Combined"}
            </button>
          </div>
          <div style={{width: "70%"}}>
            <div style={{display: 'flex'}}>
              {classes.slice(0,5).map((tag, id) => (
                <button
                  key={tag}
                  onClick={() => handleTagSelection(tag)}
                  className={selectedTags.includes(tag) ? 'button-top-page-active' :'button-top-page'}
                  style={{width: "20%", display: 'flex', justifyContent: "center"}}
                >
                  <img className="small-image" src={roleIcon[id]} alt={id}/>
                  {tag}
                </button>
              ))}
            </div>
            <div style={{display: "flex", fontSize: "10px", textAlign: "center"}}>
              {classes.slice(5).sort().map(tag => (
                <div style={{display: 'flex', width: `${100 / (classes.slice(5).length || 1)}%`}}>
                  <button
                    key={tag}
                    onClick={() => handleTagSelection(tag)}
                    className={selectedTags.includes(tag) ? 'button-top-page-active' :'button-top-page'}
                    style={{width: "100%", display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center"}}
                    >
                    <img className="small-image" src={classesPhotos[tag]} alt={tag}/>
                    {tag}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Pool items={tiers[currentRole].pool} searchTerm={searchTerm} selectedTags={selectedTags} role={currentRole} championTags={championTags} />
      </DragDropContext>
      <button className='xd' style={{ width: "100%", borderRadius: "30px" }} onClick={saveTierList}>Save Tier List</button>
    </div>
  );
};

const Tier = ({ role, tier, items, showHeader }) => (
  <Droppable droppableId={`${role}-${tier}`} direction="horizontal">
    {(provided) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
        className="tier"
        style={{ backgroundColor: tierColors[tier] || "#FFFFFF", margin: "5px" }}
      >
        {(showHeader || role==="top")  && <h2>{tier}</h2>} {/* Only show the tier heading when needed */}
          {items.map((championId, index) => (
            <Champion key={championId} champion={championId} index={index} />
          ))}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);


const Pool = ({ items, searchTerm, selectedTags, role, championTags }) => {
  const filteredItems = items.filter(championId => {
    const championName = championData[championId]?.toLowerCase();
    const matchesSearchTerm = championName.includes(searchTerm.toLowerCase());
    const matchesTags = selectedTags.length === 0 || selectedTags.every(tag => championTags[championId]?.includes(tag));

    return matchesSearchTerm && matchesTags;
  });
  const sortedItems = sortChampionIds(filteredItems);

  return (
    <Droppable droppableId={`${role}-pool`} direction="horizontal">
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="pool"
        >
          {sortedItems.map((championId, index) => (
            <Champion key={championId} champion={championId} index={index} />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const Champion = ({ champion, index }) => (
  <Draggable key={champion} draggableId={champion} index={index}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className={`champion ${snapshot.isDragging ? 'dragging' : ''}`}
        style={{
          ...provided.draggableProps.style,
          margin: '5px',
          transition: 'all 0.2s ease',
          width: "50px"
        }}
      >
        <img style={{ width: "50px" }} src={championPhotos[championData[champion]]} alt={champion} />
      </div>
    )}
  </Draggable>
);

export default TierlistForm;
