import React, { useEffect, useState } from 'react';
import "./HomePage.css";
import { TeamIcons } from './data/TeamIcons';
import { Link } from 'react-router-dom';
import iconDictionary from './data/Icons';
import { useSeason } from './SeasonContext';
import { championPhotos } from './data/ChampionsIcon';
import axiosInstance from './backend/axiosInstance';

const HomePage = () => {
  const { currentSeasonTime } = useSeason();
  document.title = "Home";
  const [teamScores, setTeamScores] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState('');
  const [selectedLeague2, setSelectedLeague2] = useState('all');
  const [leagues, setLeagues] = useState([]);
  const [teamStats, setTeamStats] = useState({});
  const [officials, setOfficials] = useState([]);
  const [scrims, setScrims] = useState([]);
  const [teammatesData, setTeamMatesData] = useState([]);
  const [playersData, setPlayersData] = useState([]);
  const [soloQPlayersData, setSoloQPlayersData] = useState([]);

  const [selectedViewType, setSelectedViewType] = useState('soloQ');

  const [aggregatedPlayerData, setAggregatedPlayerData] = useState({});

  const [selectedView, setSelectedView] = useState('playerStats'); // Default to player stats
  const [apiUsageData, setApiUsageData] = useState([]);

  const getPlayerNameWithoutTeamTag = (fullName) => {
    const parts = fullName.split(' ');
    return parts.slice(1).join(' ');
  };

  const fetchPlayerPhoto = async (playerName) => {
    try {
      const response = await axiosInstance.get(`playerphoto/?player_name=${(getPlayerNameWithoutTeamTag(playerName))}`);
      if (response.data && Array.isArray(response.data) && response.data.length > 0) {
        return response.data[0].link; // Access the 'link' from the first item in the array
      } else {
        return iconDictionary["human"]; // Return default image if no data or empty array
      }
    } catch (error) {
      console.error(`Error fetching photo for ${playerName}:`, error);
      return iconDictionary["human"]; // Return default image on error
    }
  };

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axiosInstance
      .get('unique_leagues/?start=' + start + "&end=" + end)
      .then(response => {
        const leaguesData = response.data;
        if (leaguesData.length > 0) {
          setLeagues(leaguesData);
          setSelectedLeague(leaguesData[0]);
        } else {
          console.warn('No leagues data available');
        }
      })
      .catch(error => {
        console.error('Error fetching leagues:', error);
      });
      axiosInstance
      .get('parsedscrims/?team='+localStorage.getItem("team")+'&last5games=true')
      .then((response) => {
        const sortedMatchData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setScrims(sortedMatchData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
      axiosInstance.get(`teammates/?name=${localStorage.getItem("team")}&start=${start}&end=${end}`)
      .then(async response => {
        const sortedData = response.data.sort((a, b) => a.Role - b.Role);

        const updatedData = await Promise.all(
          sortedData.map(async (player) => {
            const playerPhoto = await fetchPlayerPhoto(player.summonerName);
            return { ...player, playerPhoto };
          })
        );

        setTeamMatesData(updatedData);

        // Now fetch soloQ data for each teammate
        const soloQPromises = updatedData.map(player => {
          return axiosInstance.get(`soloQ/?playername=${getPlayerNameWithoutTeamTag(player.summonerName)}&recent=true`)
            .then(soloQResponse => ({
              summonerName: player.summonerName,
              soloQData: soloQResponse.data,
            }))
            .catch(error => {
              console.error(`Error fetching soloQ data for ${player.summonerName}:`, error);
              return null;
            });
        });

        Promise.all(soloQPromises)
          .then(results => {
            const validSoloQData = results.filter(result => result !== null);
            setSoloQPlayersData(validSoloQData);
          });
      })
      .catch(error => {
        console.error('Error fetching teammates data:', error);
      });

      axiosInstance
      .get('last2weeks/?team='+localStorage.getItem("team"))
      .then((response) => {
        setPlayersData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [currentSeasonTime]);

  useEffect(() => {
    if (selectedLeague) {
      const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
      axiosInstance.get('parsed_official_games/?start=' + start + "&end=" + end)
        .then(response => {
          const data = response.data;
          const filteredData = data.filter(game => game.leagueName === selectedLeague);
          setTeamScores(filteredData);
          const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
          setOfficials(sortedData);
        })
        .catch(error => {
          console.error('Error fetching parsed official games:', error);
        });
    }
  }, [selectedLeague, currentSeasonTime]);

  useEffect(() => {
    if (teamScores.length > 0) {
      const stats = calculateTeamStats(teamScores);
      setTeamStats(stats);
    }
  }, [teamScores]);

  const fetchApiUsage = async () => {
    try {
      const response = await axiosInstance.get('/apirequest/?team='+localStorage.getItem("team"));
      setApiUsageData(response.data);
    } catch (error) {
      console.error('Error fetching API usage data:', error);
    }
  };

  useEffect(() => {
    if (selectedView === 'apiUsage') {
      fetchApiUsage(); // Fetch data only when API Usage is selected
    }
  }, [selectedView]);

  const calculateTeamStats = (teamScores) => {
    const teamStatsData = {};

    teamScores.forEach((game) => {
      const { team1, team2, winner } = game;

      if (!teamStatsData[team1]) {
        teamStatsData[team1] = { matchesPlayed: 0, wins: 0, losses: 0 };
      }
      if (!teamStatsData[team2]) {
        teamStatsData[team2] = { matchesPlayed: 0, wins: 0, losses: 0 };
      }

      teamStatsData[team1].matchesPlayed++;
      teamStatsData[team2].matchesPlayed++;

      if (winner === 100) {
        teamStatsData[team1].wins++;
        teamStatsData[team2].losses++;
      } else if (winner === 200) {
        teamStatsData[team1].losses++;
        teamStatsData[team2].wins++;
      }
    });

    const teamStatsArray = Object.entries(teamStatsData).map(([team, stats]) => ({
      team,
      ...stats,
    }));

    teamStatsArray.sort((a, b) => {
      if (a.wins !== b.wins) {
        return b.wins - a.wins;
      }
      if (a.matchesPlayed !== b.matchesPlayed) {
        return b.matchesPlayed - a.matchesPlayed;
      }
      return a.team.localeCompare(b.team);
    });

    const teamStatsWithPlace = {};
    let place = 1;
    let prevStats = null;
    let numTeamsWithSamePlace = 0;

    teamStatsArray.forEach((stats, index) => {
      if (prevStats && (stats.wins !== prevStats.wins || stats.matchesPlayed !== prevStats.matchesPlayed)) {
        place += numTeamsWithSamePlace;
        numTeamsWithSamePlace = 0;
      }
      if (prevStats && stats.wins === prevStats.wins && stats.matchesPlayed === prevStats.matchesPlayed) {
        numTeamsWithSamePlace++;
      } else {
        numTeamsWithSamePlace = 1;
      }
      teamStatsWithPlace[stats.team] = { ...stats, place };
      prevStats = stats;
    });

    return teamStatsWithPlace;
  };

  useEffect(() => {
    const aggregateData = () => {
      const data = {};
      playersData.forEach(player => {
        const { summonerName, championName, winner } = player; // Assuming `result` is 'win' or 'loss'
        if (!data[summonerName]) {
          data[summonerName] = {};
        }
        if (!data[summonerName][championName]) {
          data[summonerName][championName] = { gamesPlayed: 0, wins: 0 };
        }
        data[summonerName][championName].gamesPlayed++;
        if (winner === 1) {
          data[summonerName][championName].wins++;
        }
      });
      setAggregatedPlayerData(data);
    };

    if (playersData.length > 0) {
      aggregateData();
    }
  }, [playersData]);

  const filteredOfficials = selectedLeague2 === 'all' ? officials : officials.filter(game => game.leagueName === selectedLeague2);

  return (
    <div style={{height: "100%"}}>
      <div className="container-line">
        <div style={{ width: "25%" }} className="container">
          {leagues.length > 0 ? (
            <>
              <table className="team-stats-table">
                <thead>
                  <tr>
                    <th style={{ width: "0%" }}>
                      <select
                        id="league-select"
                        value={selectedLeague}
                        onChange={(e) => setSelectedLeague(e.target.value)}
                      >
                        {leagues.map((league) => (
                          <option key={league} value={league} style={{ color: 'black' }}>
                            {league}
                          </option>
                        ))}
                      </select>
                    </th>
                    <th>Team</th>
                    <th>W</th>
                    <th>L</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(teamStats).map(team => (
                    <tr key={team}>
                      <td>{teamStats[team].place}</td>
                      <td>
                        <Link to={`/team/`+team} style={{ color: 'inherit' }}>
                          <img style={{ width: "25px" }} src={TeamIcons[team]} alt="" />{team}
                        </Link>
                      </td>
                      <td>{teamStats[team].wins}</td>
                      <td>{teamStats[team].losses}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <p>No leagues available</p>
          )}
        </div>
        <div style={{ width: '73%', textAlign: 'center' }} className="container">
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <div></div>

        <div style={{display: "flex"}}>
          <img src={TeamIcons[localStorage.getItem("team")]} alt='' className='big-image'/>
          <Link to={`/team/`+localStorage.getItem("team")} style={{ color: 'inherit' }}>
            <h3>{localStorage.getItem("team")}</h3>
          </Link>
        </div>
        <select
          value={selectedView}
          onChange={(e) => setSelectedView(e.target.value)}
          style={{ padding: '5px', fontSize: '16px' }}
        >
          <option value="playerStats">Player Stats</option>
          <option value="apiUsage">Ranking</option>
        </select>
      </div>

      {selectedView === 'playerStats' ? (
        <div>


<div style={{display: 'flex'}}>
{teammatesData.length > 4 ? (
  teammatesData.map((player, index) => (
    <>
      <div key={index} style={{ width: `${100 / (teammatesData.length || 1)}%`, textAlign: 'center' }}>
        <img
          style={{ width: "100%" }}
          src={player.playerPhoto} // Use the fetched photo or fallback
          alt={player.summonerName}
        />
        <div style={{display:'flex', flexDirection: 'column'}}>
          <Link to={`/player/${player.summonerName}`} style={{color: 'inherit' }}>
            {getPlayerNameWithoutTeamTag(player.summonerName)}
          </Link>
        </div>
      </div>
    </>
  ))
): (
  <p>No players available</p>
) }
</div>

<div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', width: "100%", justifyContent: 'center' }}>
<h4>Last 2 weeks</h4>
<select
  style={{ marginLeft: '10px' }}
  value={selectedViewType}
  onChange={(e) => setSelectedViewType(e.target.value)}
>
  <option value="soloQ">SoloQ</option>
  <option value="scrims">Scrims</option>
</select>
</div>

<div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
{selectedViewType === 'scrims' ? (
teammatesData.length > 4 ? (
  teammatesData.map((player) => {
    const { summonerName } = player;
    const championsData = aggregatedPlayerData[summonerName];

    return (
      <div
        key={summonerName}
        style={{ width: `${100 / (teammatesData.length || 1)}%`, textAlign: 'center', marginBottom: '20px' }}
      >
        <h5>{getPlayerNameWithoutTeamTag(summonerName)}</h5>
        {championsData ? (
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
            {Object.entries(championsData)
              .sort(([, a], [, b]) => b.gamesPlayed - a.gamesPlayed)
              .map(([championName, { gamesPlayed, wins }]) => {
                const winRate = (gamesPlayed > 0) ? (wins / gamesPlayed) : 0;
                const winRatePercentage = (winRate * 100).toFixed(0);
                const color = winRate > 0.5 ? 'green' : 'red'; // Adjust threshold as needed

                return (
                  <div
                    key={`${summonerName}-${championName}`}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >

                    <img
                      src={championPhotos[championName]} // Replace with actual path to your images
                      alt={championName}
                      style={{ width: '30px', height: '30px' }}
                    />

                    <div
                        style={{
                          width: '30px',
                          height: '5px',
                          backgroundColor: 'green',
                          transform: `scaleX(${winRate})`,
                          transformOrigin: 'left',
                          zIndex: 1, // Ensure this is on top
                        }}
                      />
                      <div
                        style={{
                          width: '30px',
                          height: '5px',
                          backgroundColor: 'red',
                          top: "30px",
                          position: "absolute",
                          zIndex: 0, // Ensure this is behind the green bar
                        }}
                      />
                    <p style={{ marginTop: '2px', fontSize: '12px', color: 'black' }}>{gamesPlayed}</p>
                  </div>
                );
              })}
          </div>
        ) : (
          <p>No champions data available</p>
        )}
      </div>
    );
  })
) : (
  <p>No scrims data available</p>
)
) : (
soloQPlayersData.length > 0 ? (
  soloQPlayersData.map((playerData, index) => {
    const aggregatedChampionData = {};

    if (playerData.soloQData) {
      playerData.soloQData.forEach((game) => {
        const { champion_name, win } = game;
        if (!aggregatedChampionData[champion_name]) {
          aggregatedChampionData[champion_name] = {
            gamesPlayed: 0,
            wins: 0,
          };
        }

        aggregatedChampionData[champion_name].gamesPlayed += 1;
        if (win) {
          aggregatedChampionData[champion_name].wins += 1;
        }
      });
    }
    let totalGamesPlayed = 0;
    let totalWins = 0;

    Object.values(aggregatedChampionData).forEach(({ gamesPlayed, wins }) => {
      totalGamesPlayed += gamesPlayed;
      totalWins += wins;
    });

    const totalWinRate = totalGamesPlayed > 0 ? totalWins / totalGamesPlayed : 0;

    return (
      <div
        key={index}
        style={{
          width: `${100 / (soloQPlayersData.length || 1)}%`,
          textAlign: 'center',
          marginBottom: '20px',
        }}
      >
        <h5>{getPlayerNameWithoutTeamTag(playerData.summonerName)}</h5>
        {totalGamesPlayed > 0 ? (
          <>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {Object.entries(aggregatedChampionData)
                .sort(([, a], [, b]) => b.gamesPlayed - a.gamesPlayed) // Sort champions by gamesPlayed descending
                .map(([championName, { gamesPlayed, wins }], gameIndex) => {
                  const championWinRate = gamesPlayed > 0 ? wins / gamesPlayed : 0;

                  return (
                    <div
                      key={gameIndex}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                      }}
                    >
                      <img
                        src={championPhotos[championName]} // Replace with actual path to your images
                        alt={championName}
                        style={{ width: '30px', height: '30px' }}
                      />

                      {/* Win rate bar per champion */}
                      <div
                        style={{
                          width: '30px',
                          height: '5px',
                          backgroundColor: 'green',
                          transform: `scaleX(${championWinRate})`,
                          transformOrigin: 'left',
                          zIndex: 1, // Ensure this is on top
                        }}
                      />
                      <div
                        style={{
                          width: '30px',
                          height: '5px',
                          backgroundColor: 'red',
                          top: '30px',
                          position: 'absolute',
                          zIndex: 0, // Ensure this is behind the green bar
                        }}
                      />
                      <p style={{ marginTop: '2px', fontSize: '12px', color: 'black' }}>
                        {gamesPlayed}
                      </p>
                    </div>
                  );
                })}
            </div>
          </>
        ) : (
          <p>No SoloQ data available</p>
        )}
      </div>
    );
  })

) : (
  <p>No soloQ data available</p>
))}
</div>
        </div>
      ) : (
        <div>
          <h3>Last 2 Weeks Ranking</h3>
          {apiUsageData.length > 0 ? (
            <table className="custom-table" style={{ margin: '0 auto', width: '80%', backgroundColor: "whitesmoke" }}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>User</th>

                  <th>Point</th>
                  <th>Last Point Earned</th>
                </tr>
              </thead>
              <tbody>
                {apiUsageData.sort((a,b)=> (b.request_count- a.request_count)).map((user, index) => (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td>{user.user}</td>
                    <td>{(user.request_count/17).toFixed(0)}</td>
                    <td>{user.last_request ? new Date(user.last_request).toLocaleString() : 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No Ranking data available</p>
          )}
        </div>
      )}
    </div>
      </div>

      <div className="container-line">
        <div style={{margin: "10px", borderRadius: "8px", border: "2px solid rgb(0, 242, 255)", width: "50%", padding: "10px", backgroundColor: "#d1d1d1"}}>
          <Link to={`/matchhistory/`} style={{ color: 'inherit' }}>
            <h3>Match History</h3>
          </Link>
          <div>
            {officials.length > 0 ? (
              <>
                <table className="custom-table">
                  <thead>
                    <tr style={{height: "50px"}}>
                      <th style={{ width: "25%" }}>
                        <select
                          id="league-select"
                          value={selectedLeague2}
                          onChange={(e) => setSelectedLeague2(e.target.value)}
                        >
                          <option value="all" style={{ color: 'black' }}>All</option>
                          {leagues.map((league) => (
                            <option key={league} value={league} style={{ color: 'black' }}>
                              {league}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th style={{ textAlign: 'center' }}>Team</th>
                      <th style={{ textAlign: 'center' }}>Result</th>
                      <th style={{ textAlign: 'center' }}>Team</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredOfficials.slice(0, 5).map((official, index) => (
                      <tr style={{height: "50px"}} key={index}>
                        <td style={{backgroundColor: "white"}}>
                          <Link to={`/match/${official.gameId}`} style={{ textDecoration: "none", color: 'inherit'}}>
                            {official.leagueName}
                          </Link>
                        </td>
                        <td style={{ textAlign: 'center', backgroundColor: official.winner === 100 ? "#7dfd7d" : "#ff6969"}}>
                          <Link to={`/match/${official.gameId}`} style={{ textDecoration: "none", color: 'inherit' }}>
                            <img style={{ width: "25px" }} src={TeamIcons[official.team1]} alt=""/> {official.team1}
                          </Link>
                        </td>
                        <td style={{ textAlign: 'center',  backgroundColor: "white"}}>
                          <Link to={`/match/${official.gameId}`} style={{ textDecoration: "none", color: 'inherit'  }}>
                            {official.winner === 200 ? "0" : "1"}:{official.winner === 100 ? "0" : "1"}
                          </Link>
                        </td>
                        <td style={{ textAlign: 'center', backgroundColor: official.winner === 200 ? "#7dfd7d" : "#ff6969" }}>
                          <Link to={`/match/${official.gameId}`} style={{ textDecoration: "none", color: 'inherit' }}>
                            <img style={{ width: "25px" }} src={TeamIcons[official.team2]} alt=""/> {official.team2}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <p>No match history available</p>
            )}
          </div>
        </div>
        <div style={{margin: "10px", borderRadius: "8px", border: "2px solid rgb(0, 242, 255)", width: "50%", padding: "10px", backgroundColor: "#d1d1d1"}}>
          <Link to={`/scrimhistory/`} style={{ color: 'inherit' }}>
            <h3>Scrim History</h3>
          </Link>
          <div style={{height: "100%"}}>
            {scrims.length > 0 ? (
              <>
                <table className="custom-table">
                  <thead>
                    <tr style={{height: "50px"}}>
                      <th style={{ width: "25%" }}>Date Time </th>
                      <th style={{ textAlign: 'center' }}>Team</th>
                      <th style={{ textAlign: 'center' }}>Result</th>
                      <th style={{ textAlign: 'center' }}>Team</th>
                    </tr>
                  </thead>
                  <tbody>
                    {scrims.slice(0, 5).map((scrim, index) => (
                      <tr style={{height: "50px"}} key={index}>
                        <td style={{backgroundColor: "white"}}>
                          <Link to={`/scrim/${scrim.scrimId}`} style={{ textDecoration: "none", color: 'inherit' }}>
                            {new Date(scrim.date).toLocaleDateString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                          </Link>
                        </td>
                        <td style={{ textAlign: 'center', backgroundColor: scrim.winner === 100 ? "#7dfd7d" : "#ff6969"}}>
                          <Link to={`/scrim/${scrim.scrimId}`} style={{ textDecoration: "none", color: 'inherit' }}>
                            <img style={{ width: "25px" }} src={TeamIcons[scrim.team1]} alt=""/> {scrim.team1}
                          </Link>
                        </td>
                        <td style={{ textAlign: 'center',  backgroundColor: "white"}}>
                          <Link to={`/scrim/${scrim.scrimId}`} style={{ textDecoration: "none", color: 'inherit' }}>
                            {scrim.winner === 200 ? "0" : "1"}:{scrim.winner === 100 ? "0" : "1"}
                          </Link>
                        </td>
                        <td style={{ textAlign: 'center', backgroundColor: scrim.winner === 200 ? "#7dfd7d" : "#ff6969" }}>
                          <Link to={`/scrim/${scrim.scrimId}`} style={{ textDecoration: "none", color: 'inherit' }}>
                            <img style={{ width: "25px" }} src={TeamIcons[scrim.team2]} alt=""/> {scrim.team2}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <p>No match history available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
