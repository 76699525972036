import React, { useEffect, useState } from "react";
import { useSeason } from "./SeasonContext";
import axiosInstance from "./backend/axiosInstance";
import GameTimelineComponent from "./GameTimelineComponent";
import { useParams } from "react-router-dom";

const PlayerPositionAceumComponent = () => {
  const { currentSeasonTime } = useSeason();
  const { name } = useParams();

  document.title = "Player Position";

  const [matchData, setMatchData] = useState([]);
  const [playerPositions, setPlayerPositions] = useState({});
  const [selectedGamesForChart, setSelectedGamesForChart] = useState([]);
  const [selectedGamesForAverage, setSelectedGamesForAverage] = useState([]);
  const [showAverage, setShowAverage] = useState(false);

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map((date) => date.trim());
    axiosInstance
      .get(
        `officials_for_player/?start=${start}&end=${end}&player_name=${encodeURIComponent(name)}`
      )
      .then((response) => {
        const sortedMatchData = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setMatchData(sortedMatchData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [currentSeasonTime, name]);

  useEffect(() => {
    if (matchData.length > 0) {
      const fetchPositions = async () => {
        const positionRequests = matchData.map((game) =>
          axiosInstance.get(`playerposition/?gameId=${game.gameId}&playername=${name}`).then((res) => ({
            gameId: game.gameId,
            data: res.data,
          }))
        );

        try {
          const results = await Promise.all(positionRequests);
          const positionsMap = results.reduce((acc, { gameId, data }) => {
            acc[gameId] = data;
            return acc;
          }, {});
          setPlayerPositions(positionsMap);
        } catch (error) {
          console.error("Error fetching player positions:", error);
        }
      };

      fetchPositions();
    }
  }, [matchData]);

  const toggleGameSelection = (gameId, type) => {
    if (type === "chart") {
      setSelectedGamesForChart((prev) =>
        prev.includes(gameId) ? prev.filter((id) => id !== gameId) : [...prev, gameId]
      );
    } else if (type === "average") {
      setSelectedGamesForAverage((prev) =>
        prev.includes(gameId) ? prev.filter((id) => id !== gameId) : [...prev, gameId]
      );
    }
  };

  return (
    <div style={{ display: "flex", color: "white" }}>
      {/* Left Section - Single Chart */}
      <div style={{ width: "70%" }}>
      <GameTimelineComponent
        selectedGames={selectedGamesForChart}
        playerPositions={playerPositions}
        matchData={matchData} // Pass match data for correct team names in legend
        showAverage={showAverage}
        averageGames={selectedGamesForAverage}
      />
      </div>

      <div style={{ width: "30%", display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
          <h3>Games to Display</h3>
          {matchData.map((game) => (
            <label key={game.gameId} style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
              <input
                type="checkbox"
                checked={selectedGamesForChart.includes(game.gameId)}
                onChange={() => toggleGameSelection(game.gameId, "chart")}
              />
              <span style={{ marginLeft: "8px", color: "blue" }}>{game.team1}</span>
              <span style={{ marginLeft: "8px", color: "white" }}>vs</span>
              <span style={{ marginLeft: "8px", color: "red" }}>{game.team2}</span>

            </label>
          ))}
        </div>

        {/* Checkboxes for selecting games to be included in the average */}
        <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
          <h3>Games for Average</h3>
          {matchData.map((game) => (
            <label key={game.gameId} style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
              <input
                type="checkbox"
                checked={selectedGamesForAverage.includes(game.gameId)}
                onChange={() => toggleGameSelection(game.gameId, "average")}
              />
              <span style={{ marginLeft: "8px", color: "blue" }}>{game.team1}</span>
              <span style={{ marginLeft: "8px", color: "white" }}>vs</span>
              <span style={{ marginLeft: "8px", color: "red" }}>{game.team2}</span>
            </label>
          ))}
        </div>

        {/* Toggle for showing the average */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3>Toggle Average</h3>
          <label style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
            <input
              type="checkbox"
              checked={showAverage}
              onChange={() => setShowAverage(!showAverage)}
            />
            <span style={{ marginLeft: "8px" }}>Show Global Average</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default PlayerPositionAceumComponent;
