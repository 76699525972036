import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale
);

const GameTimelineComponent = ({ selectedGames, playerPositions, matchData, showAverage, averageGames }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (!selectedGames.length || !Object.keys(playerPositions).length) return;

    // Extract unique timestamps across all selected games (in seconds)
    const allTimestamps = [...new Set(selectedGames.flatMap(gameId =>
      (playerPositions[gameId] || []).map(p => p.time) // Keep raw seconds
    ))].sort((a, b) => a - b);

    // Generate datasets for each selected game
    const datasets = selectedGames.map(gameId => {
      const gameData = playerPositions[gameId] || [];
      const timeSeries = allTimestamps.map(time => {
        const point = gameData.find(p => p.time === time);
        return point ? (point.x - point.y) / Math.sqrt(2) : null;
      });

      // Get team names for legend
      const gameInfo = matchData.find(game => game.gameId === gameId);
      const teamLabel = gameInfo ? `${gameInfo.team1} vs ${gameInfo.team2}` : `Game ${gameId}`;

      return {
        label: teamLabel, // Update legend label to show "Team1 vs Team2"
        data: timeSeries,
        borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`, // Random color per game
        borderWidth: 2,
        pointRadius: 0, // No large points
        pointHoverRadius: 0,
        fill: false,
      };
    });

    // Compute global average distance based on selected games for average
    if (showAverage && averageGames.length > 0) {
      const globalAvgDistances = allTimestamps.map(time => {
        const allDistances = averageGames.flatMap(gameId => {
          const gameData = playerPositions[gameId] || [];
          return gameData.filter(p => p.time === time)
            .map(p => (p.x - p.y) / Math.sqrt(2));
        });

        return allDistances.length ? allDistances.reduce((sum, val) => sum + val, 0) / allDistances.length : null;
      });

      datasets.push({
        label: "Global Average",
        data: globalAvgDistances,
        borderColor: "#aaaaaa",
        borderWidth: 2,
        borderDash: [3, 3], // Dashed line for global average
        pointRadius: 0,
        pointHoverRadius: 0,
        fill: false,
      });
    }

    setChartData({
      labels: allTimestamps, // Use seconds for x-axis
      datasets,
    });
  }, [selectedGames, playerPositions, matchData, showAverage, averageGames]);

  return (
    <div style={{ width: "100%", margin: "20px 0" }}>
      {chartData ? (
        <Line
          data={chartData}
          style={{ width: "100%" }}
          options={{
            plugins: {
              legend: {
                labels: {
                  color: "white", // Adjust to match theme
                },
              },
            },
            scales: {
              y: {
                suggestedMin: -8000,
                suggestedMax: 8000,
                ticks: {
                  callback: function (value) {
                    if (value === 8000) return "Top";
                    if (value === 0) return "Mid";
                    if (value === -8000) return "Bot";
                    return "";
                  },
                },
              },
              x: {
                title: {
                  display: true,
                  text: "Time",
                },
                ticks: {
                  callback: function (value, index) {
                    return index % 60 === 0 ? `${Math.floor(value / 60)}m` : ""; // Show every minute
                  },
                },
              },
            },
          }}
        />
      ) : (
        <p>Loading chart...</p>
      )}
    </div>
  );
};

export default GameTimelineComponent;
