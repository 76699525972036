
import rune8275 from '../../rune_photos/8275.png';
import rune7200_Domination from '../../rune_photos/7200_Domination.png';
import rune7201_Precision from '../../rune_photos/7201_Precision.png';
import rune7202_Sorcery from '../../rune_photos/7202_Sorcery.png';
import rune7203_Whimsy from '../../rune_photos/7203_Whimsy.png';
import rune7204_Resolve from '../../rune_photos/7204_Resolve.png';
import rune8005 from '../../rune_photos/8005.png';
import rune8008 from '../../rune_photos/8008.png';
import rune8009 from '../../rune_photos/8009.png';
import rune8010 from '../../rune_photos/8010.png';
import rune8014 from '../../rune_photos/8014.png';
import rune8017 from '../../rune_photos/8017.png';
import rune8021 from '../../rune_photos/8021.png';
import rune8105 from '../../rune_photos/8105.png';
import rune8106 from '../../rune_photos/8106.png';
import rune8112 from '../../rune_photos/8112.png';
import rune8120 from '../../rune_photos/8120.png';
import rune8124 from '../../rune_photos/8124.png';
import rune8126 from '../../rune_photos/8126.png';
import rune8128 from '../../rune_photos/8128.png';
import rune8134 from '../../rune_photos/8134.png';
import rune8135 from '../../rune_photos/8135.png';
import rune8136 from '../../rune_photos/8136.png';
import rune8138 from '../../rune_photos/8138.png';
import rune8139 from '../../rune_photos/8139.png';
import rune8143 from '../../rune_photos/8143.png';
import rune8210 from '../../rune_photos/8210.png';
import rune8214 from '../../rune_photos/8214.png';
import rune8224 from '../../rune_photos/8224.png';
import rune8226 from '../../rune_photos/8226.png';
import rune8229 from '../../rune_photos/8229.png';
import rune8230 from '../../rune_photos/8230.png';
import rune8232 from '../../rune_photos/8232.png';
import rune8233 from '../../rune_photos/8233.png';
import rune8234 from '../../rune_photos/8234.png';
import rune8236 from '../../rune_photos/8236.png';
import rune8237 from '../../rune_photos/8237.png';
import rune8242 from '../../rune_photos/8242.png';
import rune8299 from '../../rune_photos/8299.png';
import rune8304 from '../../rune_photos/8304.png';
import rune8306 from '../../rune_photos/8306.png';
import rune8313 from '../../rune_photos/8313.png';
import rune8316 from '../../rune_photos/8316.png';
import rune8321 from '../../rune_photos/8321.png';
import rune8345 from '../../rune_photos/8345.png';

import rune8137 from '../../rune_photos/8137.png';
import rune8140 from '../../rune_photos/8140.png';
import rune8141 from '../../rune_photos/8141.png';


import rune8347 from '../../rune_photos/8347.png';
import rune8351 from '../../rune_photos/8351.png';
import rune8352 from '../../rune_photos/8352.png';
import rune8360 from '../../rune_photos/8360.png';
import rune8369 from '../../rune_photos/8369.png';
import rune8410 from '../../rune_photos/8410.png';
import rune8429 from '../../rune_photos/8429.png';
import rune8437 from '../../rune_photos/8437.png';
import rune8439 from '../../rune_photos/8439.png';
import rune8444 from '../../rune_photos/8444.png';
import rune8446 from '../../rune_photos/8446.png';
import rune8451 from '../../rune_photos/8451.png';
import rune8453 from '../../rune_photos/8453.png';
import rune8463 from '../../rune_photos/8463.png';
import rune8465 from '../../rune_photos/8465.png';
import rune8473 from '../../rune_photos/8473.png';
import rune9101 from '../../rune_photos/9101.png';
import rune9103 from '../../rune_photos/9103.png';
import rune9104 from '../../rune_photos/9104.png';
import rune9105 from '../../rune_photos/9105.png';
import rune9111 from '../../rune_photos/9111.png';
import rune9923 from '../../rune_photos/9923.png';
import runeCelestialBody from '../../rune_photos/CelestialBody.png';
import runeChrysalis from '../../rune_photos/Chrysalis.png';
import runeDangerousGame from '../../rune_photos/DangerousGame.png';
import runeIronSkin from '../../rune_photos/IronSkin.png';
import runeKleptomancy from '../../rune_photos/Kleptomancy.png';
import runeLastResortIcon from '../../rune_photos/LastResortIcon.png';
import runeLegend_Heroism from '../../rune_photos/Legend_Heroism.png';
import runeLegend_Infamy from '../../rune_photos/Legend_Infamy.png';
import runeLegend_Tenacity from '../../rune_photos/Legend_Tenacity.png';
import runeLethalTempoTemp from '../../rune_photos/LethalTempoTemp.png';
import runeMasterKey from '../../rune_photos/MasterKey.png';
import runeMirrorShell from '../../rune_photos/MirrorShell.png';
import runeRavenousHunter from '../../rune_photos/RavenousHunter.png';
import runeRunesIcon from '../../rune_photos/RunesIcon.png';
import runeTheUltimateHat from '../../rune_photos/TheUltimateHat.png';
import rune8401 from "../../rune_photos/8401.png";

const runeIconDictionary = {
  rune8137,
  rune8140,
  rune8141,

  rune8401: rune8401,
  rune8275: rune8275,
  Domination: rune7200_Domination,
  Precision: rune7201_Precision,
  Sorcery: rune7202_Sorcery,
  Inspiration: rune7203_Whimsy,
  Resolve: rune7204_Resolve,
  rune8005: rune8005,
  rune8008: rune8008,
  rune8009: rune8009,
  rune8010: rune8010,
  rune8014: rune8014,
  rune8017: rune8017,
  rune8021: rune8021,
  rune8105: rune8105,
  rune8106: rune8106,
  rune8112: rune8112,
  rune8120: rune8120,
  rune8124: rune8124,
  rune8126: rune8126,
  rune8128: rune8128,
  rune8134: rune8134,
  rune8135: rune8135,
  rune8136: rune8136,
  rune8138: rune8138,
  rune8139: rune8139,
  rune8143: rune8143,
  rune8210: rune8210,
  rune8214: rune8214,
  rune8224: rune8224,
  rune8226: rune8226,
  rune8229: rune8229,
  rune8230: rune8230,
  rune8232: rune8232,
  rune8233: rune8233,
  rune8234: rune8234,
  rune8236: rune8236,
  rune8237: rune8237,
  rune8242: rune8242,
  rune8299: rune8299,
  rune8304: rune8304,
  rune8306: rune8306,
  rune8313: rune8313,
  rune8316: rune8316,
  rune8321: rune8321,
  rune8345: rune8345,
  rune8347: rune8347,
  rune8351: rune8351,
  rune8352: rune8352,
  rune8360: rune8360,
  rune8369: rune8369,
  rune8410: rune8410,
  rune8429: rune8429,
  rune8437: rune8437,
  rune8439: rune8439,
  rune8444: rune8444,
  rune8446: rune8446,
  rune8451: rune8451,
  rune8453: rune8453,
  rune8463: rune8463,
  rune8465: rune8465,
  rune8473: rune8473,
  rune9101: rune9101,
  rune9103: rune9103,
  rune9104: rune9104,
  rune9105: rune9105,
  rune9111: rune9111,
  rune9923: rune9923,
  runeCelestialBody: runeCelestialBody,
  runeChrysalis: runeChrysalis,
  runeDangerousGame: runeDangerousGame,
  runeIronSkin: runeIronSkin,
  runeKleptomancy: runeKleptomancy,
  runeLastResortIcon: runeLastResortIcon,
  runeLegend_Heroism: runeLegend_Heroism,
  runeLegend_Infamy: runeLegend_Infamy,
  runeLegend_Tenacity: runeLegend_Tenacity,
  runeLethalTempoTemp: runeLethalTempoTemp,
  runeMasterKey: runeMasterKey,
  runeMirrorShell: runeMirrorShell,
  runeRavenousHunter: runeRavenousHunter,
  runeRunesIcon: runeRunesIcon,
  runeTheUltimateHat: runeTheUltimateHat,
};

export default runeIconDictionary;
