import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import logo from './Psyduck.png';
import iconDictionary from './components/data/Icons';
import { Link } from 'react-router-dom';
import { useSeason } from './components/SeasonContext';
import axiosInstance from './components/backend/axiosInstance';

function Sidebar({ loggedIn }) {
  const { currentSeason, currentSeasonTime, seasonOptions, setCurrentSeason } = useSeason();
  const [propositions, setPropositions] = useState([]);
  const [playersName, setPlayersName] = useState([]);
  const [teamName, setTeamName] = useState([]);

  useEffect(() => {
    const [start, end] = currentSeasonTime.split(" - ").map(date => date.trim());
    axiosInstance
      .get('unique_players/?start=' + start + "&end=" + end)
      .then(response => {
        setPlayersName(response.data);
      })
      .catch(error => {
        console.error(error);
      });
      axiosInstance
      .get(`unique_teames/`)
      .then(response => {
        setTeamName(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, [currentSeasonTime]);

  const handleInputChange = (value) => {
    const filteredPropositions = [...playersName, ...teamName].filter((name) =>
      name.toLowerCase().includes(value.toLowerCase())
    );
    setPropositions(filteredPropositions);
  };

  const handleAutocompleteSelect = (e) => {
    const selectedValue = e.target.value;
    const isValueInPropositions = propositions.includes(selectedValue);

    if (isValueInPropositions) {
      const searchQuery = encodeURIComponent(selectedValue);
      const isPlayer = playersName.includes(selectedValue);

      if (isPlayer) {
        window.location.href = `/player/${searchQuery}`;
      } else {
        window.location.href = `/team/${searchQuery}`;
      }
    }
  };

  const handleCreditsClick = () => {
    window.location.href = `/credits`;
  };

  const sidebarItems = [
    { title: 'Login', url: '/login', icon: 'Login' },
    { title: 'Home', url: '/home', icon: "Home"},
    { title: 'Teams', url: '/teams', icon: "Teams"},
    { title: 'Players', url: '/players', icon: "Player"},
    { title: 'Match History', url: '/matchhistory', icon: "Officials_History"},
    { title: 'Drafts', url: '/drafts', icon: "Drafts"},
    { title: 'Tierlist', url: '/tierlist', icon: "Tierlist"},
    { title: 'Scrim History', url: '/scrimhistory', icon: "Scrims_History"},
    { title: 'Team Drafts', url: '/teamsDrafts', icon: "Computer"},
    { title: 'SoloQ', url: '/leaderboard', icon: "SoloQ"},
    { title: 'Matchups', url: '/matchupfinder', icon: "Matchups"},
    { title: 'Proview', url: '/proview', icon: "Computer"},
    { title: 'Game', url: '/game/summonerSpells', icon: "Player"},

    { title: 'Other', url: '/other', icon: "Other"},
  ];

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-header">
        <img src={logo} alt="JD" className="sidebar-logo" />
        <br/>
        <input
          type="text"
          placeholder="Search"
          className="sidebar-search"
          list={`proposition`}
          onChange={(e) => handleInputChange(e.target.value)}
          onInput={handleAutocompleteSelect}
        />
        {propositions.length > 0 && (
          <datalist id={`proposition`}>
            {propositions.map((prop, index) => {
              if (teamName.includes(prop)) {
                return <option key={index} value={`${prop}`} />;
              }
              return <option key={index} value={prop} />;
            })}
          </datalist>
        )}
      </div>
      <div className="sidebar-container">
        {sidebarItems.map((item, index) => (
          <Link
            className="sidebar-item"
            key={index}
            to={`${item.url}`}
            style={{ textDecoration: "none" }}
          >
            <div className="sidebar-item-content">
              <img className="small-image-3" src={iconDictionary[item.icon]} alt="" />
              <div className="sidebar-item-title" style={{ textDecoration: "none" }}>{item.title}</div>
            </div>
          </Link>
        ))}
      </div>
      <div style={{display: "flex", justifyContent: 'center'}}>

        <select
          className="season-select"
          value={currentSeason}
          onChange={(e) => setCurrentSeason(e.target.value)}
          style={{width: "80%"}}
          >
          {seasonOptions.map((season, index) => (
            <option key={index} value={season.label}>
              {season.label}
            </option>
          ))}
        </select>
      </div>
      <div style={{ width: "100%", fontSize: "10px", color: "white", textAlign: 'center' }}>
        {localStorage.getItem("team") ? localStorage.getItem("team") : ""}
      </div>
      <div style={{ width: "100%", fontSize: "10px", color: "white", textAlign: 'center' }}>
        Version 2.1.4
      </div>
      <div style={{ width: "100%", color: "white", fontSize: "10px", textAlign: 'center' }} onClick={() => handleCreditsClick()}>
        Credits
      </div>
    </div>
  );
}

export default Sidebar;
