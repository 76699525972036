import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Player.css';
import { championPhotos } from "./data/ChampionsIcon";
import CustomSelect from './base/CustomSelect';
import 'react-pivottable/pivottable.css';
import 'rc-slider/assets/index.css';
import ChampionPerformanceComponent from './PlayerChampionsPerformance';
import { useSeason } from './SeasonContext';
import ProximityPlayerComponent from './ProximityPlayer';
import { getGreenCellColor, getRedGreenCell, getRedGreenCellno0, getRedCellColor } from './base/colorUtils';
import axiosInstance from './backend/axiosInstance';
import html2canvas from 'html2canvas';

const PlayerPageScrim = () => {
  const {currentSeasonTime } = useSeason();
  const { name } = useParams();
  document.title = "SCRIM " + name;
  const [playerData, setPlayerData] = useState([]);
  const [filterPlayerData, setFilterPlayerData] = useState([]);

  const [proximityData, setProximityData] = useState([]);
  const [filteredProximityData, setFilteredProximityData] = useState([]);

  const encodedPlayerName = encodeURIComponent(name);

  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const [selectedPatch, setSelectedPatch] = useState([]);
  const [filterPatch, setFilterPatch] = useState([]);
  const [patchOption, setPatchOption] = useState([]);

  const twoWeeksAgo = new Date();
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
  const [startDate, setStartDate] = useState(twoWeeksAgo);
  const [endDate, setEndDate] = useState(new Date());

  const [championDictionary, setChampionDictionary] = useState({});

  const [filteredPlayerData, setFilteredPlayerData] = useState({});

  const captureScreenshot = (table) => {
    // Select the element you want to screenshot
    const element = document.getElementById(table);

    html2canvas(element)
      .then((canvas) => {
        // Create an image
        const image = canvas.toDataURL('image/png');

        // You can now use this image for your presentation
        // For example, download it as an image file
        const link = document.createElement('a');
        link.download = 'screenshot.png';
        link.href = image;
        link.click();
      })
      .catch(err => {
        console.error('Error capturing screenshot', err);
      });
  };


  useEffect(() => {
    // Fetch player data based on the player name from the URL
    axiosInstance
      .get(`playersScrims/?name=${encodedPlayerName}`)
      .then(response => {
        const sortedData = response.data.slice().sort((a, b) => b.patch.localeCompare(a.patch));
        setPlayerData(sortedData);
        const uniquePatches = [...new Set(sortedData.map((match) => match.patch))];
        const formattedPatchOptions = uniquePatches.sort().map((patch) => ({
          value: patch,
          label: patch,
        }));
        setPatchOption(formattedPatchOptions);
      })
      .catch(error => {
        console.error(error);
      });

      axiosInstance
      .get(`proximityScrims/?name=${encodedPlayerName}`)
      .then(response => {
        const sortedData = response.data.slice().sort((a, b) => b.patch.localeCompare(a.patch));
        setProximityData(sortedData);
      })
      .catch(error => {
        console.error(error);
      });

    }, []);

    useEffect(() => {
      if (playerData) {
        const filteredPlayersPick = playerData.filter((player) => {
          const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
          const matchDate = new Date(player.date);
          return (!startDate || matchDate >= startDate) && (!endDate || matchDate <= endDate) && isPatchGood;
        });
        setFilteredPlayerData(filteredPlayersPick);
        const newChampionDictionary = {};

        filteredPlayersPick.forEach((player) => {
          const championName = player.championName;

          if (!newChampionDictionary[championName]) {
            newChampionDictionary[championName] = {
              matchesPlayed: 0,
              totalWins: 0,
            };
          }

          newChampionDictionary[championName].matchesPlayed += 1;
          newChampionDictionary[championName].totalWins += player.winner;
        });

        // Calculate win rates and sort the champion data
        for (const championName in newChampionDictionary) {
          const champion = newChampionDictionary[championName];
          champion.winRate = ((champion.totalWins * 100) / champion.matchesPlayed).toFixed(2);
        }

        const sortedChampions = Object.entries(newChampionDictionary).sort(
          (a, b) => b[1].matchesPlayed - a[1].matchesPlayed
        );

        // Map the sorted entries back to an object
        const sortedChampionObject = Object.fromEntries(sortedChampions);

        setFilterPlayerData(filteredPlayersPick);
        setChampionDictionary(sortedChampionObject);
      }
    }, [playerData, filterPatch, startDate, endDate]);

    useEffect(() => {
      if (playerData) {
        const filteredPlayersPick = playerData.filter((player) => {
          const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
          const matchDate = new Date(player.date);
          return (!startDate || matchDate >= startDate) && (!endDate || matchDate <= endDate) &&  isPatchGood;
        });
      }
      if (proximityData) {
        const filteredProximityData1 = proximityData.filter((player) => {
          const isPatchGood = filterPatch.length === 0 || filterPatch.includes(player.patch);
          return isPatchGood;
        });
        setFilteredProximityData(filteredProximityData1);
      }
    }, [playerData, filterPatch, startDate, endDate, proximityData]);

  const handlePatchChange = (selectedOptions) => {
    //setMatchesForCurrentPage([]);
    const leagueValues = selectedOptions.map((option) => option.value);
    setFilterPatch(leagueValues);
    setSelectedPatch(selectedOptions);
  };

  const handleSort = (columnName) => {
    if (sortedColumn === columnName) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(columnName);
      setSortDirection('asc');
    }
  };

  const handleStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(0, 0, 0, 0); // Set hours to 00:00:00
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setHours(23, 50, 59, 999); // Set hours to 23:59:59
    setEndDate(selectedDate);
  };

  function calculateAverageDamagePerMinute(player) {
    return (player.DamageDealt / player.gameDuration).toFixed(0);
  }

  function calculateAverageCSPerMinute(player) {
    return (player.CS / player.gameDuration).toFixed(2);
  }

  function calculateAverageGoldPerMinute(player) {
    return (player.GoldEarned / player.gameDuration).toFixed(0);
  }

  const sortedFilterPlayerData = filterPlayerData.slice().sort((a, b) => {
    a.averageCSPerMinute = calculateAverageCSPerMinute(a);
    b.averageCSPerMinute = calculateAverageCSPerMinute(b);

    a.averageGoldPerMinute = calculateAverageGoldPerMinute(a);
    b.averageGoldPerMinute = calculateAverageGoldPerMinute(b);
    a.dmgPerMinute15ToEnd = ((a.DamageDealt - a.DMG15) / (a.gameDuration - 15)).toFixed(2);
    b.dmgPerMinute15ToEnd = ((b.DamageDealt - b.DMG15) / (b.gameDuration - 15)).toFixed(2);
    a.goldPerMinute15ToEnd = ((a.GoldEarned - a.GOLD15) / (a.gameDuration - 15)).toFixed(2);
    b.goldPerMinute15ToEnd = ((b.GoldEarned - b.GOLD15) / (b.gameDuration - 15)).toFixed(2);
    // Sorting logic for the new column
    if (sortedColumn === 'goldPerMinute15ToEnd') {
      return sortDirection === 'asc'
        ? parseFloat(a.goldPerMinute15ToEnd) - parseFloat(b.goldPerMinute15ToEnd)
        : parseFloat(b.goldPerMinute15ToEnd) - parseFloat(a.goldPerMinute15ToEnd);
    }
    else if (sortedColumn === 'dmgPerMinute15ToEnd') {
      return sortDirection === 'asc'
        ? parseFloat(a.dmgPerMinute15ToEnd) - parseFloat(b.dmgPerMinute15ToEnd)
        : parseFloat(b.dmgPerMinute15ToEnd) - parseFloat(a.dmgPerMinute15ToEnd);
    }
    else if (sortedColumn === 'damageShared' || sortedColumn === 'goldShared' || sortedColumn === 'JungleProximity') {
      const aValue = a[sortedColumn] * 100;
      const bValue = b[sortedColumn] * 100;
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
    } else if (sortedColumn === 'DamageDealt')
    {
      const aValue = calculateAverageDamagePerMinute(a);
      const bValue = calculateAverageDamagePerMinute(b);
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
    }
    else if (sortedColumn === 'CS') {
    return sortDirection === 'asc'
      ? parseFloat(a.averageCSPerMinute) - parseFloat(b.averageCSPerMinute)
      : parseFloat(b.averageCSPerMinute) - parseFloat(a.averageCSPerMinute);
    } else if (sortedColumn === 'GoldEarned') {
    return sortDirection === 'asc'
      ? parseFloat(a.averageGoldPerMinute) - parseFloat(b.averageGoldPerMinute)
      : parseFloat(b.averageGoldPerMinute) - parseFloat(a.averageGoldPerMinute);
   }
    else if (sortedColumn === 'championName' || sortedColumn === 'enemyChampionName') {

      const aValue = a[sortedColumn].toUpperCase(); // Convert to uppercase for case-insensitive sorting
      const bValue = b[sortedColumn].toUpperCase(); // Convert to uppercase for case-insensitive sorting
      return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    }
    else
    {
      // Handle sorting for other columns
      return sortDirection === 'asc'
        ? a[sortedColumn] - b[sortedColumn]
        : b[sortedColumn] - a[sortedColumn];
    }
  });

  function averageValue(columnName) {
    const total = filterPlayerData.reduce((acc, record) => acc + record[columnName], 0);

    return (total / filterPlayerData.length); // You can adjust the number of decimal places
  }

  const getMaxValueDivided = (property, divisor) => {
    return Math.max(
      ...filterPlayerData
        .map((player) => player[property] / player[divisor])
        .map((value) => parseFloat(value))
    );
  };

  const getMinValueDivided = (property, divisor) => {
    return Math.min(
      ...filterPlayerData
        .map((player) => player[property] / player[divisor])
        .map((value) => parseFloat(value))
    );
  };

  const getMaxValue = (property) => {
    return Math.max(...Object.values(filterPlayerData).map((player) => player[property]));
  };

  const getMinValue = (property) => {
    return Math.min(...Object.values(filterPlayerData).map((player) => player[property]));
  };

  const handleGameClick = (teamName) => {
    window.location.href = `/scrim/${teamName}`;
  };

  return (
    <div className='main' >
      <div className='filters'>
        Starting Date
        <input
          type="date"
          value={startDate?.toISOString().split('T')[0]}
          onChange={handleStartDateChange}
        />
        Ending Date
        <input
          type="date"
          value={endDate?.toISOString().split('T')[0]}
          onChange={handleEndDateChange}
        />
        Patch
        <div className='short-chooser'>
          <CustomSelect options={patchOption} selectedOption={selectedPatch} onOptionChange={handlePatchChange} />
        </div>

      </div>

      <div style={{backgroundColor: 'white'}}>
      {filterPlayerData ? (
        <div className='team-performance-table-container'>
          <table className='team-performance-table'>
            <thead>
              <tr>
                <th onClick={() => handleSort('championName')}>championName</th>
                <th onClick={() => handleSort('enemyChampionName')}>enemyName</th>
                <th onClick={() => handleSort('winner')}>WR%</th>
                <th onClick={() => handleSort('killsAndAssists8')}>K&A@8</th>
                <th onClick={() => handleSort('deaths8')}>D@8</th>
                <th onClick={() => handleSort('goldDiff8')}>GD@8</th>
                <th onClick={() => handleSort('csDiff8')}>CSD@8</th>
                <th onClick={() => handleSort('expDiff8')}>XPD@8</th>
                <th onClick={() => handleSort('wardPlaced8')}>wardPlaced8</th>
                <th onClick={() => handleSort('wardKilled8')}>wardKilled8</th>
                <th onClick={() => handleSort('visionScore8')}>visionScore8</th>
                <th onClick={() => handleSort('killsAndAssists14')}>K&A@14</th>
                <th onClick={() => handleSort('deaths14')}>D@14</th>
                <th onClick={() => handleSort('goldDiff14')}>GD@14</th>
                <th onClick={() => handleSort('csDiff14')}>CSD@14</th>
                <th onClick={() => handleSort('expDiff14')}>XPD@14</th>
                <th onClick={() => handleSort('wardPlaced14')}>wardPlaced14</th>
                <th onClick={() => handleSort('wardKilled14')}>wardKilled14</th>
                <th onClick={() => handleSort('visionScore14')}>visionScore14</th>
                <th onClick={() => handleSort('DMG15')}>DMG/M@15</th>
                <th onClick={() => handleSort('DMGShared15')}>DMG%15</th>
                <th onClick={() => handleSort('dmgPerMinute15ToEnd')}>DMG/M 15-End</th>
                <th onClick={() => handleSort('DMGShared15end')}>DMG%15end</th>
                <th onClick={() => handleSort('DamageDealt')}>DMG/M</th>
                <th onClick={() => handleSort('damageShared')}>DMG%</th>
                <th onClick={() => handleSort('GD5')}>GD@5</th>
                <th onClick={() => handleSort('GD10')}>GD@10</th>
                <th onClick={() => handleSort('GD15')}>GD@15</th>
                <th onClick={() => handleSort('GOLD15')}>GOLD/M@15</th>
                <th onClick={() => handleSort('GOLDShared15')}>GOLD%15</th>
                <th onClick={() => handleSort('goldPerMinute15ToEnd')}>GOLD/M 15-End</th>
                <th onClick={() => handleSort('GOLDShared15end')}>GOLD% 15-End</th>
                <th onClick={() => handleSort('GoldEarned')}>G/M</th>
                <th onClick={() => handleSort('goldShared')}>G%</th>
                <th onClick={() => handleSort('JungleProximity')}>JGL%</th>
                <th onClick={() => handleSort('JungleProximityEnd')}>JGL% 15-End</th>
                <th onClick={() => handleSort('enemyJungleProximity15')}>eJGL%</th>
                <th onClick={() => handleSort('enemyJungleProximityEnd')}>eJGL% 15-End</th>
                <th onClick={() => handleSort('kills')}>K</th>
                <th onClick={() => handleSort('deaths')}>D</th>
                <th onClick={() => handleSort('assists')}>A</th>
                <th onClick={() => handleSort('wardPlaced')}>wardPlaced</th>
                <th onClick={() => handleSort('wardKilled')}>wardKilled</th>
                <th onClick={() => handleSort('visionScore')}>visionScore</th>
                <th onClick={() => handleSort('csDiff_0_5')}>CSD 0-5</th>
                <th onClick={() => handleSort('csDiff_5_10')}>CSD 5-10</th>
                <th onClick={() => handleSort('csDiff_10_15')}>CSD 10-15</th>
                <th onClick={() => handleSort('csDiff_0_15')}>CSD 0-15</th>
                <th onClick={() => handleSort('csDiff_20_end')}>CSD 20-END</th>
                <th onClick={() => handleSort('csPerMin_0_5')}>CS/M 0-5</th>
                <th onClick={() => handleSort('csPerMin_5_10')}>CS/M 5-10</th>
                <th onClick={() => handleSort('csPerMin_10_15')}>CS/M 10-15</th>
                <th onClick={() => handleSort('csPerMin_0_15')}>CS/M 0-15</th>
                <th onClick={() => handleSort('CS')}>CS/M</th>
                <th onClick={() => handleSort('TakenEnemyCamp4_7')}>TakenEnemyCamp4-7</th>
                <th onClick={() => handleSort('IsolatedDeaths20plus')}>IsolatedDeaths20+</th>
                <th onClick={() => handleSort('ControlWardsBought')}>ControlWardsBought</th>
                <th onClick={() => handleSort('TowerPlateDMG')}>TowerDMG@14</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {sortedFilterPlayerData.map((record, index) => {
                  record.dmgPerMinute15ToEnd =  ((record.DamageDealt - record.DMG15) / (record.gameDuration - 15));
                  record.goldPerMinute15ToEnd =  ((record.GoldEarned - record.GOLD15) / (record.gameDuration - 15));
                  record.min5 = 300
                  record.min15 = 900
                  record.csDiff_0_5 = record.CSD5;
                  record.csDiff_5_10 = record.CSD10 - record.CSD5;
                  record.csDiff_10_15 = record.CSD15 - record.CSD10;
                  record.csDiff_0_15 = record.CSD15;
                  record.csDiff_20_end = record.CSD - record.CSD20;
                  record.csPerMin_0_5 = (record.CS5 / 5);
                  record.csPerMin_5_10 = ((record.CS10 - record.CS5) / 5);
                  record.csPerMin_10_15 = ((record.CS15 - record.CS10) / 5);
                  record.csPerMin_0_15 = (record.CS15 / 15);
                  const damagePerMinute = (record.DamageDealt / record.gameDuration).toFixed(0);
                  const csPerMinute = (record.CS / record.gameDuration).toFixed(2);
                  const goldPerMinute = (record.GoldEarned / record.gameDuration).toFixed(0);
                return (
                  <tr key={index}>
                    <td style={{ textAlign: 'left', justifyContent: 'left' }}><img className='small-image' src={championPhotos[record.championName]} alt="" />{record.championName}</td>
                    <td style={{ textAlign: 'left' }}><img className='small-image' src={championPhotos[record.enemyChampionName]} alt="" />{record.enemyChampionName}</td>
                    <td>{record.winner ? 'Yes' : 'No'}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.killsAndAssists8, getMaxValue('killsAndAssists8')) }}>{record.killsAndAssists8.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedCellColor(record.deaths8, getMaxValue('deaths8')) }}>{record.deaths8.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.goldDiff8, getMinValue('goldDiff8'), getMaxValue('goldDiff8'))}}>{record.goldDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.csDiff8, getMinValue('csDiff8'), getMaxValue('csDiff8'))}}>{record.csDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.expDiff8, getMinValue('expDiff8'), getMaxValue('expDiff8'))}}>{record.expDiff8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.wardPlaced8, getMinValue('wardPlaced8'), getMaxValue('wardPlaced8'))}}>{record.wardPlaced8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.wardKilled8, getMinValue('wardKilled8'), getMaxValue('wardKilled8'))}}>{record.wardKilled8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.visionScore8, getMinValue('visionScore8'), getMaxValue('visionScore8'))}}>{record.visionScore8.toFixed(0)}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.killsAndAssists14, getMaxValue('killsAndAssists14')) }}>{record.killsAndAssists14.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedCellColor(record.deaths14, getMaxValue('deaths14')) }}>{record.deaths14.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.goldDiff14, getMinValue('goldDiff14'), getMaxValue('goldDiff14'))}}>{record.goldDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.csDiff14, getMinValue('csDiff14'), getMaxValue('csDiff14'))}}>{record.csDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.expDiff14, getMinValue('expDiff14'), getMaxValue('expDiff14'))}}>{record.expDiff14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.wardPlaced14, getMinValue('wardPlaced14'), getMaxValue('wardPlaced14'))}}>{record.wardPlaced14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.wardKilled14, getMinValue('wardKilled14'), getMaxValue('wardKilled14'))}}>{record.wardKilled14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.visionScore14, getMinValue('visionScore14'), getMaxValue('visionScore14'))}}>{record.visionScore14.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.DMG15, getMinValue('DMG15'), getMaxValue('DMG15'))}}>{(record.DMG15/15).toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.DMGShared15, getMinValue('DMGShared15'), getMaxValue('DMGShared15'))}}>{(record.DMGShared15*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.dmgPerMinute15ToEnd, getMinValue('dmgPerMinute15ToEnd'), getMaxValue('dmgPerMinute15ToEnd')) }}>{record.dmgPerMinute15ToEnd.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.DMGShared15end, getMinValue('DMGShared15end'), getMaxValue('DMGShared15end'))}}>{(record.DMGShared15end*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(damagePerMinute, getMinValueDivided('DamageDealt','gameDuration'), getMaxValueDivided('DamageDealt','gameDuration'))}}>{(damagePerMinute)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.damageShared, getMinValue('damageShared'), getMaxValue('damageShared'))}}>{(record.damageShared*100).toFixed(2)}%</td>



                    <td style={{ backgroundColor: getRedGreenCell(record.GD5, getMinValue('GD5'), getMaxValue('GD5'))}}>{(record.GD5).toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.GD10, getMinValue('GD10'), getMaxValue('GD10'))}}>{(record.GD10).toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.GD15, getMinValue('GD15'), getMaxValue('GD15'))}}>{(record.GD15).toFixed(0)}</td>

                    <td style={{ backgroundColor: getRedGreenCellno0(record.GOLD15, getMinValue('GOLD15'), getMaxValue('GOLD15'))}}>{(record.GOLD15/15).toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.GOLDShared15, getMinValue('GOLDShared15'), getMaxValue('GOLDShared15'))}}>{(record.GOLDShared15*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.goldPerMinute15ToEnd, getMinValue('goldPerMinute15ToEnd'), getMaxValue('goldPerMinute15ToEnd')) }}>{record.goldPerMinute15ToEnd.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.GOLDShared15end, getMinValue('GOLDShared15end'), getMaxValue('GOLDShared15end'))}}>{(record.GOLDShared15end*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(goldPerMinute, getMinValueDivided('GoldEarned', 'gameDuration'), getMaxValueDivided('GoldEarned', 'gameDuration'))}}>{ (goldPerMinute)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.goldShared, getMinValue('goldShared'), getMaxValue('goldShared'))}}>{(record.goldShared*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.JungleProximity, getMinValue('JungleProximity'), getMaxValue('JungleProximity'))}}>{(record.JungleProximity*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.JungleProximityEnd, getMinValue('JungleProximityEnd'), getMaxValue('JungleProximityEnd'))}}>{(record.JungleProximityEnd*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.enemyJungleProximity15, getMinValue('enemyJungleProximity15'), getMaxValue('enemyJungleProximity15'))}}>{(record.enemyJungleProximity15*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.enemyJungleProximityEnd, getMinValue('enemyJungleProximityEnd'), getMaxValue('enemyJungleProximityEnd'))}}>{(record.enemyJungleProximityEnd*100).toFixed(2)}%</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.kills, getMaxValue('kills')) }}>{record.kills}</td>
                    <td style={{ backgroundColor: getRedCellColor(record.deaths, getMaxValue('deaths')) }}>{record.deaths}</td>
                    <td style={{ backgroundColor: getGreenCellColor(record.assists, getMaxValue('assists')) }}>{record.assists}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.wardPlaced, getMinValue('wardPlaced'), getMaxValue('wardPlaced'))}}>{record.wardPlaced.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.wardKilled, getMinValue('wardKilled'), getMaxValue('wardKilled'))}}>{record.wardKilled.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.visionScore, getMinValue('visionScore'), getMaxValue('visionScore'))}}>{record.visionScore.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.csDiff_0_5, getMinValue('csDiff_0_5'), getMaxValue('csDiff_0_5')) }}>
                      {record.csDiff_0_5.toFixed(2)}
                    </td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.csDiff_5_10, getMinValue('csDiff_5_10'), getMaxValue('csDiff_5_10')) }}>
                      {record.csDiff_5_10.toFixed(2)}
                    </td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.csDiff_10_15, getMinValue('csDiff_10_15'), getMaxValue('csDiff_10_15')) }}>
                      {record.csDiff_10_15.toFixed(2)}
                    </td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.csDiff_0_15, getMinValue('csDiff_0_15'), getMaxValue('csDiff_0_15')) }}>
                      {record.csDiff_0_15.toFixed(2)}
                    </td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.csDiff_20_end, getMinValue('csDiff_20_end'), getMaxValue('csDiff_20_end')) }}>
                      {record.csDiff_20_end.toFixed(2)}
                    </td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.csPerMin_0_5, getMinValue('csPerMin_0_5'), getMaxValue('csPerMin_0_5'))}}>{record.csPerMin_0_5.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.csPerMin_5_10, getMinValue('csPerMin_5_10'), getMaxValue('csPerMin_5_10'))}}>{record.csPerMin_5_10.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.csPerMin_10_15, getMinValue('csPerMin_10_15'), getMaxValue('csPerMin_10_15'))}}>{record.csPerMin_10_15.toFixed(2)}</td>
                    <td style={{ backgroundColor: getRedGreenCellno0(record.csPerMin_0_15, getMinValue('csPerMin_0_15'), getMaxValue('csPerMin_0_15'))}}>{record.csPerMin_0_15.toFixed(2)}</td>


                    <td style={{ backgroundColor: getRedGreenCellno0(csPerMinute, getMinValueDivided('CS', 'gameDuration'), getMaxValueDivided('CS', 'gameDuration'))}}>{csPerMinute}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.TakenEnemyCamp4_7, getMinValue('TakenEnemyCamp4_7'), getMaxValue('TakenEnemyCamp4_7'))}}>{record.TakenEnemyCamp4_7.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.IsolatedDeaths20plus, getMinValue('IsolatedDeaths20plus'), getMaxValue('IsolatedDeaths20plus'))}}>{record.IsolatedDeaths20plus.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.ControlWardsBought, getMinValue('ControlWardsBought'), getMaxValue('ControlWardsBought'))}}>{record.ControlWardsBought.toFixed(0)}</td>
                    <td style={{ backgroundColor: getRedGreenCell(record.TowerPlateDMG, getMinValue('TowerPlateDMG'), getMaxValue('TowerPlateDMG'))}}>{record.TowerPlateDMG.toFixed(0)}</td>
                    <td style={{ cursor: 'pointer' }} onClick={() => handleGameClick(record.scrimId)}>{"->"}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr style={{backgroundColor: "white"}}>
                <td>Average</td>
                <td></td>
                <td></td>
                <td>{averageValue('killsAndAssists8').toFixed(2)}</td>
                <td>{averageValue('deaths8').toFixed(2)}</td>
                <td>{(averageValue('goldDiff8')*1).toFixed(0)}</td>
                <td>{(averageValue('csDiff8')*1).toFixed(0)}</td>
                <td>{(averageValue('expDiff8')*1).toFixed(0)}</td>
                <td>{(averageValue('wardPlaced8')*1).toFixed(0)}</td>
                <td>{(averageValue('wardKilled8')*1).toFixed(0)}</td>
                <td>{(averageValue('visionScore8')*1).toFixed(0)}</td>
                <td>{averageValue('killsAndAssists14').toFixed(2)}</td>
                <td>{averageValue('deaths14').toFixed(2)}</td>
                <td>{(averageValue('goldDiff14')*1).toFixed(0)}</td>
                <td>{(averageValue('csDiff14')*1).toFixed(0)}</td>
                <td>{(averageValue('expDiff14')*1).toFixed(0)}</td>
                <td>{(averageValue('wardPlaced14')*1).toFixed(0)}</td>
                <td>{(averageValue('wardKilled14')*1).toFixed(0)}</td>
                <td>{(averageValue('visionScore14')*1).toFixed(0)}</td>
                <td>{(averageValue('DMG15')/15*1).toFixed(0)}</td>
                <td>{(averageValue('DMGShared15')*100).toFixed(0)}</td>
                <td>{(averageValue('dmgPerMinute15ToEnd')).toFixed(2)}</td>
                <td>{(averageValue('DMGShared15end')*100).toFixed(0)}</td>
                <td>{(averageValue('DamageDealt') / averageValue('gameDuration')).toFixed(0)}</td>
                <td>{(averageValue('damageShared') * 100).toFixed(2)}%</td>
                <td>{(averageValue('GD5')*1).toFixed(0)}</td>
                <td>{(averageValue('GD10')*1).toFixed(0)}</td>
                <td>{(averageValue('GD15')*1).toFixed(0)}</td>
                <td>{(averageValue('GOLD15')/15*1).toFixed(0)}</td>
                <td>{(averageValue('GOLDShared15')*100).toFixed(0)}</td>
                <td>{(averageValue('goldPerMinute15ToEnd')).toFixed(0)}</td>
                <td>{(averageValue('GOLDShared15end')*100).toFixed(0)}</td>
                <td>{(averageValue('GoldEarned') / averageValue('gameDuration')).toFixed(0)}</td>
                <td>{(averageValue('goldShared') * 100).toFixed(2)}%</td>
                <td>{(averageValue('JungleProximity') * 100).toFixed(2)}%</td>
                <td>{(averageValue('JungleProximityEnd') * 100).toFixed(2)}%</td>
                <td>{(averageValue('enemyJungleProximity15') * 100).toFixed(2)}%</td>
                <td>{(averageValue('enemyJungleProximityEnd') * 100).toFixed(2)}%</td>
                <td>{averageValue('kills').toFixed(2)}</td>
                <td>{averageValue('deaths').toFixed(2)}</td>
                <td>{averageValue('assists').toFixed(2)}</td>
                <td>{(averageValue('wardPlaced')*1).toFixed(0)}</td>
                <td>{(averageValue('wardKilled')*1).toFixed(0)}</td>
                <td>{(averageValue('visionScore')*1).toFixed(0)}</td>
                <td>{averageValue('csDiff_0_5').toFixed(2)}</td>
                <td>{averageValue('csDiff_5_10').toFixed(2)}</td>
                <td>{averageValue('csDiff_10_15').toFixed(2)}</td>
                <td>{averageValue('csDiff_0_15').toFixed(2)}</td>
                <td>{averageValue('csDiff_20_end').toFixed(2)}</td>

                <td>{(averageValue('csPerMin_0_5') ).toFixed(2)}</td>
                <td>{(averageValue('csPerMin_5_10') ).toFixed(2)}</td>
                <td>{(averageValue('csPerMin_10_15') ).toFixed(2)}</td>
                <td>{(averageValue('csPerMin_0_15')).toFixed(2)}</td>
                <td>{(averageValue('CS') / averageValue('gameDuration')).toFixed(2)}</td>
                <td>{(averageValue('TakenEnemyCamp4_7')*1).toFixed(0)}</td>
                <td>{(averageValue('IsolatedDeaths20plus')*1).toFixed(0)}</td>
                <td>{(averageValue('ControlWardsBought')*1).toFixed(0)}</td>
                <td>{(averageValue('TowerPlateDMG')*1).toFixed(0)}</td>

                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>

        ) : (
          <p>Loading...</p>
        )}

      </div>

      <div style={{justifyContent: 'center', display: 'flex', flexDirection: "column", alignItems: "center", marginBottom: "40px"}}>
        {championDictionary && (
          <table id="element1" style={{width: "20%", backgroundColor: "white", color: "black" }}>
            <thead>
              <tr>
                <th></th>
                <th>Champion</th>
                <th>Games</th>
                <th>WR</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(championDictionary).map(([championName, championData], champIndex) => (
                <tr key={champIndex}>
                  <td><img className="small-image" src={championPhotos[championName]} alt='' /></td>
                  <td>{championName}</td>
                  <td style={{textAlign: 'center'}}>{championData.matchesPlayed}</td>
                  <td>{championData.winRate}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <button  style={{width: "20%"}} className="screenshot-button" onClick={() => captureScreenshot('element1')}>ScreenShot</button>
      </div>

      <div style={{width: "100%"}}>
        <ChampionPerformanceComponent playerData={filterPlayerData}/>
      </div>

      {filteredProximityData && filteredProximityData.length > 0 && (
        <ProximityPlayerComponent proximityData={filteredProximityData} />
      )}
    </div>
  );
};
export default PlayerPageScrim;
