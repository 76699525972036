import React from 'react';
import mapPhotsDictionary from '../../data/MapData';
import 'rc-slider/assets/index.css';
import html2canvas from 'html2canvas';

const OracleLensComponent = ({ oracleLensData }) => {
    const mapWidth = 500;
    const mapHeight = 500;
    const minX = -120;
    const minY = -120;
    const maxX = 14970;
    const maxY = 14980;

    const oracleRadius = {
        1: 600, 2: 600, 3: 600, 4: 600, 5: 630, 6: 630, 7: 630, 8: 660,
        9: 660, 10: 660, 11: 690, 12: 690, 13: 690, 14: 720, 15: 720,
        16: 720, 17: 750, 18: 750,
    };

    const captureScreenshot = () => {
        const element = document.getElementById("heatmap-container-2");

        html2canvas(element)
            .then((canvas) => {
                const image = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.download = 'screenshot.png';
                link.href = image;
                link.click();
            })
            .catch(err => {
                console.error('Error capturing screenshot', err);
            });
    };

    const translateCoordinates = (x, y) => {
        const newX = ((x - minX) / (maxX - minX)) * mapWidth;
        const newY = ((maxY - y) / (maxY - minY)) * mapHeight;
        return { newX, newY };
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div id="heatmap-container-2" style={{ position: 'relative' }}>
                <img style={{ position: 'relative', width: `${mapWidth}px`, height: `${mapHeight}px` }}
                     src={mapPhotsDictionary["SummonersRift"]}
                     alt="Summoner's Rift Map" />

                <svg
                    style={{ position: 'absolute', top: 0, left: 0 }}
                    width={mapWidth} height={mapHeight}
                >
                    <defs>
                        <filter id="blur">
                            <feGaussianBlur stdDeviation="10" result="blurred" />
                            <feMerge>
                                <feMergeNode in="blurred" />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>

                    {/* Draw circles around each movement point to form the Oracle Lens area */}
                    {oracleLensData.map((movement, index) => {
                        if (movement.positions.length < 2) return null;

                        const teamColor = movement.side === "blue" ? "rgba(0, 0, 255, 0.3)" : "rgba(255, 0, 0, 0.3)";
                        const radius = (oracleRadius[movement.level] / (maxX - minX)) * mapWidth;

                        return movement.positions.map((pos, idx) => {
                            const { newX, newY } = translateCoordinates(pos.x, pos.y);

                            return (
                                <circle
                                    key={`${index}-${idx}`}
                                    cx={newX}
                                    cy={newY}
                                    r={radius / 3} // Divide by 3 for better spacing of circles
                                    fill={teamColor}
                                    opacity="0.5"
                                    filter="url(#blur)"
                                />
                            );
                        });
                    })}

                    {/* Draw movement path */}
                    {oracleLensData.map((movement, index) => {
                        if (movement.positions.length < 2) return null;

                        const pathPoints = movement.positions
                            .map(pos => {
                                const { newX, newY } = translateCoordinates(pos.x, pos.y);
                                return `${newX},${newY}`;
                            })
                            .join(" ");

                        return (
                            <g key={index}>
                                {/* Main Path */}
                                <polyline
                                    points={pathPoints}
                                    stroke={movement.side === "blue" ? "blue" : "red"}
                                    strokeWidth="3"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                        );
                    })}
                </svg>
            </div>
            <button style={{ width: "100%", marginTop: "10px" }}
                    className='button-top-page'
                    onClick={() => captureScreenshot()}>
                Screenshot
            </button>
        </div>
    );
};

export default OracleLensComponent;
