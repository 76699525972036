import React, { useState, useEffect } from "react";
import { championData, championPhotos } from "../data/ChampionsIcon";
import axiosInstance from "../backend/axiosInstance";
import summonerSpellDictionary from "../data/SummonerSpellData";
import runeIconDictionary from "../data/RuneIcons";
import itemIconDictionary from "../data/ItemIcons";
import Cookies from 'js-cookie';



const BASE_COOLDOWNS = {
  1: 240,
  3: 240,
  4: 300,
  6: 240,
  7: 240,
  11: 90,
  13: 240,
  12: 300,
  14: 180,
  21: 180,
};

const COOLDOWN_REDUCTION = {
  "rune8347": {
    300: 273,
    240: 218,
    180: 164,
    90: 82,
  },
  "boots": {
    300: 254,
    240: 203,
    180: 153,
    90: 76,
  },
  "rune8347_boots": {
    300: 234,
    240: 188,
    180: 141,
    90: 70,
  },
};

const calculateCooldown = (spellId, hasRune, hasBoots) => {
  const baseCd = BASE_COOLDOWNS[spellId] || 0;

  if (hasRune && hasBoots) {
    return COOLDOWN_REDUCTION["rune8347_boots"][baseCd] || baseCd;
  } else if (hasRune) {
    return COOLDOWN_REDUCTION["rune8347"][baseCd] || baseCd;
  } else if (hasBoots) {
    return COOLDOWN_REDUCTION["boots"][baseCd] || baseCd;
  }
  return baseCd;
};

const SummonerSpellTimerComponent = () => {
  const [championRoleData, setChampionRoleData] = useState([]);
  const [selectedChampions, setSelectedChampions] = useState({});
  const [championBuilds, setChampionBuilds] = useState({}); // Store build data for each champion
  const [timers, setTimers] = useState([180]); // ✅ Default first timer at 3:00
  const [expectedCooldowns, setExpectedCooldowns] = useState({});

  const [currentTimerIndex, setCurrentTimerIndex] = useState(0);
  const [chatLogs, setChatLogs] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [summonerCooldowns, setSummonerCooldowns] = useState({});

  if (!localStorage.getItem('apikey')) {
    localStorage.setItem('apikey', "b4zbFjQyY341cxvdHNbKVrMGmyEY3V");
    // Optional: Set a cookie as well
    Cookies.set('apikey', "b4zbFjQyY341cxvdHNbKVrMGmyEY3V", { expires: 1, secure: true });
  }




  const sites = ["Red Side", "Blue Side"];

  const rune_group_dict = {
    8112: "Domination",
    8128: "Domination",
    9923: "Domination",
    8126: "Domination",
    8139: "Domination",
    8143: "Domination",
    8137: "Domination",
    8140: "Domination",
    8141: "Domination",
    8135: "Domination",
    8105: "Domination",
    8106: "Domination",

    8351: "Inspiration",
    8360: "Inspiration",
    8369: "Inspiration",
    8306: "Inspiration",
    8304: "Inspiration",
    8321: "Inspiration",
    8313: "Inspiration",
    8352: "Inspiration",
    8345: "Inspiration",
    8347: "Inspiration",
    8410: "Inspiration",
    8316: "Inspiration",

    8005: "Precision",
    8008: "Precision",
    8021: "Precision",
    8010: "Precision",
    9101: "Precision",
    9111: "Precision",
    8009: "Precision",
    9104: "Precision",
    9105: "Precision",
    9103: "Precision",
    8014: "Precision",
    8017: "Precision",
    8299: "Precision",

    8437: "Resolve",
    8439: "Resolve",
    8465: "Resolve",
    8446: "Resolve",
    8463: "Resolve",
    8401: "Resolve",
    8429: "Resolve",
    8444: "Resolve",
    8473: "Resolve",
    8451: "Resolve",
    8453: "Resolve",
    8242: "Resolve",

    8214: "Sorcery",
    8229: "Sorcery",
    8230: "Sorcery",
    8224: "Sorcery",
    8226: "Sorcery",
    8275: "Sorcery",
    8210: "Sorcery",
    8234: "Sorcery",
    8233: "Sorcery",
    8237: "Sorcery",
    8232: "Sorcery",
    8236: "Sorcery",
}

  const summoner = {
    1: "Cleanse",
    3: "Exhuast",
    4: "Flash",
    6: "Ghost",
    7: "Heal",
    11: "Smite",
    13: "Clarity",
    12: "Teleport",
    14: "Ignite",
    21: "Barier",
  }

const basicMessages = [
  "gg",
  "signals that enemies are missing",
  "is on the way",
  "this top man",
  "keep save",
  "ggwp",
  "is asking for assistance",
  "use english",
  "lmfao",
  "bruh",
  "gg",
  "9x"
]

useEffect(() => {
  if (
    timers.length > 0 &&
    Object.keys(selectedChampions).length > 0 &&
    Object.keys(championBuilds).length > 0 &&
    chatLogs.length === 0
  ) {
    generateChatLogs(timers[0]);  // ✅ Ensures chat logs generate once everything is ready
  }
}, [timers, selectedChampions, championBuilds]); // ✅ Runs when timers, champions, and builds are set


useEffect(() => {
  if (timers.length > 0 && Object.keys(selectedChampions).length > 0 && Object.keys(championBuilds).length > 0) {
    generateChatLogs(timers[currentTimerIndex]); // ✅ Run chat logs when timer updates
  }
}, [currentTimerIndex, timers, selectedChampions]);

useEffect(() => {
  setSelectedSite(sites[Math.floor(Math.random() * sites.length)]);
  generateRandomTimers();
}, []);

const generateRandomTimers = () => {
  const minTime = 3 * 60; // 3:00 in seconds
  const maxTime = 40 * 60; // 40:00 in seconds
  let randomTimers = new Set();

  while (randomTimers.size < 5) {
    let randomTime = Math.floor(Math.random() * (maxTime - minTime + 1)) + minTime;
    randomTimers.add(randomTime);
  }

  const sortedTimers = [...randomTimers].sort((a, b) => a - b);
  setTimers(sortedTimers);
};

const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

  useEffect(() => {
    axiosInstance.get('champion_roles/')
      .then(response => {
        setChampionRoleData(response.data.data);
      })
      .catch(error => {
        console.error("Error fetching champion classes", error);
      });
  }, []);

  useEffect(() => {
    if (championRoleData.length > 0) {
      selectRandomChampions();
    }
  }, [championRoleData]);

  useEffect(() => {
    if (Object.keys(selectedChampions).length > 0) {
      fetchChampionBuilds();
    }
  }, [selectedChampions]);

  const generateChatLogs = (time) => {
    let logs = [];
    if (!selectedChampions || !selectedSite || Object.keys(selectedChampions).length === 0) return;

    const enemyChampions = selectedSite === "Blue Side"
      ? Object.values(selectedChampions).map(champs => champs[1]).filter(Boolean)
      : Object.values(selectedChampions).map(champs => champs[0]).filter(Boolean);

    const teamMateChampions = selectedSite === "Blue Side"
      ? Object.values(selectedChampions).map(champs => champs[0]).filter(Boolean)
      : Object.values(selectedChampions).map(champs => champs[1]).filter(Boolean);

    let newExpectedCooldowns = {};

    const summonerPingCount = Math.random() < 0.5 ? 1 : 2;

    for (let i = 0; i < summonerPingCount; i++) {
      const randomChampion = enemyChampions[Math.floor(Math.random() * enemyChampions.length)];
      const teamMateChampion = teamMateChampions[Math.floor(Math.random() * teamMateChampions.length)];

      if (!championBuilds[teamMateChampion]) continue;

      const randomSpell = Math.random() < 0.5
        ? championBuilds[randomChampion]?.summoner1Id
        : championBuilds[randomChampion]?.summoner2Id;

        const hasRune = [...Array(7)].some((_, i) => championBuilds[randomChampion]?.[`rune${i}`] === 8347);
        const hasBoots = [...Array(7)].some((_, i) => (championBuilds[randomChampion]?.[`item${i}`] === 3158 || championBuilds[randomChampion]?.[`item${i}`] === 3171) );


      const correctCooldown = calculateCooldown(randomSpell, hasRune, hasBoots);
      const cooldownEndTime = Math.round((time + correctCooldown) / 10) * 10;
      if (!newExpectedCooldowns[randomChampion]) {
        newExpectedCooldowns[randomChampion] = {};
      }
      newExpectedCooldowns[randomChampion][randomSpell] = cooldownEndTime;

      logs.push({
        type: "ping",
        time: formatTime(time),
        summoner: championBuilds[teamMateChampion].lastSummonerName,
        champion: championData[teamMateChampion],
        target: championData[randomChampion],
        spell: summoner[randomSpell] || randomSpell,
      });
    }
    const messageCount = Math.floor(Math.random() * 2) + 3;
    for (let i = 0; i < messageCount; i++) {
      const new_champ = teamMateChampions[Math.floor(Math.random() * teamMateChampions.length)]
      logs.push({
        type: "message",
        time: formatTime(time),
        summoner: championBuilds[new_champ]?.lastSummonerName || "Unknown",
        champion: championData[new_champ],
        message: basicMessages[Math.floor(Math.random() * basicMessages.length)],
      });
    }

    logs = logs.sort(() => Math.random() - 0.5);

    setChatLogs(logs);
    setExpectedCooldowns(newExpectedCooldowns);
  };


console.log(expectedCooldowns)

  const selectRandomChampions = () => {
    const roles = ["Top", "Jgl", "Mid", "Bot", "Sup"];
    const roleBasedChampions = {};

    // Group champions by their role
    roles.forEach(role => {
      roleBasedChampions[role] = championRoleData
        .filter(champion => champion.className === role)
        .map(champion => champion.championId);
    });

    const getRandomChampions = (champions) => {
      if (champions.length < 2) return champions;
      const shuffled = [...champions].sort(() => 0.5 - Math.random()); // Shuffle
      return shuffled.slice(0, 2); // Pick first two
    };

    const selected = {};
    roles.forEach(role => {
      selected[role] = getRandomChampions(roleBasedChampions[role]);
    });

    setSelectedChampions(selected);
  };


  const fetchChampionBuilds = async () => {
    const builds = {};

    await Promise.all(
      Object.values(selectedChampions).flat().map(async (championId) => {
        try {
          const response = await axiosInstance.get(`randombuild/?champion=${championData[championId]}`);
          builds[championId] = response.data; // Store build data by champion ID
        } catch (error) {
          console.error(`Error fetching build for ${championId}`, error);
          builds[championId] = null; // Store null if request fails
        }
      })
    );

    setChampionBuilds(builds);
  };

  const handleCooldownChange = (championId, summonerKey, value) => {
    setSummonerCooldowns(prevState => ({
      ...prevState,
      [championId]: {
        ...prevState[championId],
        [summonerKey]: value
      }
    }));
  };


  const parseMMSS = (mmss) => {
    const mmssStr = mmss.toString().padStart(4, "0"); // Ensure it has 4 digits (e.g., 0525 -> "0525")
    const minutes = parseInt(mmssStr.slice(0, -2), 10); // Extract all but last 2 digits as minutes
    const seconds = parseInt(mmssStr.slice(-2), 10); // Extract last 2 digits as seconds

    if (isNaN(minutes) || isNaN(seconds) || seconds >= 60) {
      return NaN; // Invalid input (e.g., seconds should be 00-59)
    }

    return minutes * 60 + seconds; // Convert to total seconds
  };

  const handleSubmitCooldowns = () => {
    console.log("User Entered Cooldowns:", summonerCooldowns);
    console.log("Expected Cooldowns:", expectedCooldowns);

    let errors = [];
    Object.entries(summonerCooldowns).forEach(([championId, cooldowns]) => {
      Object.entries(cooldowns).forEach(([summonerKey, enteredTime]) => {
        const spellId = championBuilds[championId]?.[summonerKey + "Id"];
        const expectedTime = expectedCooldowns[championId]?.[spellId];

        if (expectedTime) {
          const enteredTimeInt = parseMMSS(enteredTime);

          if (isNaN(enteredTimeInt)) {
            errors.push(`Invalid cooldown entry for ${championData[championId]} - ${summoner[spellId]}`);
            return;
          }

          const timeDifference = Math.abs(expectedTime - enteredTimeInt);

          if (timeDifference > 5) { // Allow slight difference of ±5 sec
            errors.push(
              `Incorrect cooldown for ${championData[championId]} - ${summoner[spellId]} (Expected: ${formatTime(expectedTime)}, Entered: ${formatTime(enteredTimeInt)})`
            );
          }
        }
      });
    });

    if (errors.length > 0) {
      alert("Some cooldowns are incorrect:\n" + errors.join("\n"));
    } else {
      alert("All cooldowns are correct!");
    }
  };

  const handleNextTimer = () => {
    if (currentTimerIndex < timers.length - 1) {
      setCurrentTimerIndex(currentTimerIndex + 1);
    } else {
      // Generate new timers
      generateRandomTimers();
      setCurrentTimerIndex(0);
      setSelectedChampions({});
      setChampionBuilds({});
      setChatLogs([]);
      setSummonerCooldowns({});
      setExpectedCooldowns({});

      // Select new champions
      selectRandomChampions();
    }
  };



  return (
    <div style={{color: "white", textAlign: "center"}}>
        <h3>{formatTime(timers[currentTimerIndex])}  ({currentTimerIndex + 1}/{timers.length})</h3>
        <div style={{ color: "white", border: "2px solid pink", padding: "20px" }}>
        {Object.entries(selectedChampions).map(([role, champions]) => (
            <div key={role}>
            <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                {champions.map((championId, idx) => (
                <div key={championId} style={{display: "flex", width: "40%", justifyContent: "flex-start"}}>
                    {championBuilds[championId] && (
                        <>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <img
                            src={summonerSpellDictionary[championBuilds[championId].summoner1Id]}
                            style={{ width: "25px", height: "25px" }}
                            />
                                            <img
                            src={summonerSpellDictionary[championBuilds[championId].summoner2Id]}
                            style={{ width: "25px", height: "25px" }}
                            />
                        </div>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <img
                                src={runeIconDictionary["rune"+championBuilds[championId].rune0]}
                                style={{ width: "25px", height: "25px" }}
                            />
                            <img
                                src={runeIconDictionary[rune_group_dict[championBuilds[championId].rune4]]}
                                style={{ width: "25px", height: "25px" }}
                            />
                        </div>
                        </>
                    )}
                    <img
                    src={championPhotos[championData[championId]]}
                    alt={`Champion ${championId}`}
                    style={{ width: "50px", height: "50px" }}
                    />
                                    {championBuilds[championId] && (
                        <>
                        <div style={{ width: "25%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                            {championBuilds[championId].cs}
                        </div>
                        <div style={{ width: "25%",display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                            {championBuilds[championId].kills}/{championBuilds[championId].deaths}/{championBuilds[championId].assists}

                        </div>
                        <div>
                            <div>
                            {championBuilds[championId].lastSummonerName}
                            </div>
                            <div style={{display: "flex"}}>
                                <img
                                    src={itemIconDictionary["img"+championBuilds[championId].item0]}
                                    style={{ width: "25px", height: "25px" }}
                                />
                                <img
                                    src={itemIconDictionary["img"+championBuilds[championId].item1]}
                                    style={{ width: "25px", height: "25px" }}
                                />
                                <img
                                    src={itemIconDictionary["img"+championBuilds[championId].item2]}
                                    style={{ width: "25px", height: "25px" }}
                                />
                                <img
                                    src={itemIconDictionary["img"+championBuilds[championId].item3]}
                                    style={{ width: "25px", height: "25px" }}
                                />
                                <img
                                    src={itemIconDictionary["img"+championBuilds[championId].item4]}
                                    style={{ width: "25px", height: "25px" }}
                                />
                                <img
                                    src={itemIconDictionary["img"+championBuilds[championId].item5]}
                                    style={{ width: "25px", height: "25px" }}
                                />
                                <img
                                    src={itemIconDictionary["img"+championBuilds[championId].item6]}
                                    style={{ width: "25px", height: "25px" }}
                                />
                            </div>
                        </div>

                        </>
                    )}
                </div>
                ))}
            </div>
            </div>
        ))}
        </div>
        <div style={{width: "100%", display: "flex"}}>
          <div style={{ width: "50%", color: "white", padding: "50px", textAlign: "left" }}>
            <h2>Chat</h2>
            {chatLogs.map((log, index) => (
              <p key={index}>
                <span style={{ color: "lightblue" }}>{log.time} </span>
                <span style={{ color: "cyan", fontWeight: "bold" }}>{log.summoner} </span>
                {log.type === "ping" ? (
                  <>
                    <span style={{ color: "#61dafb" }}>({log.champion}) </span>
                    <span style={{ color: "red", fontWeight: "bold" }}>{log.target} </span>
                    <span style={{ color: "orange", fontWeight: "bold" }}>{log.spell}</span>
                  </>
                ) : (
                  <>
                    <span style={{ color: "#61dafb" }}>({log.champion}) </span>
                    <span style={{ color: "gray", fontStyle: "italic" }}>{log.message}</span>
                  </>
                )}
              </p>
            ))}
          </div>
          <div style={{ width: "50%", display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", padding: "50px" }}>
            Response should look like this 1130 1500 0450
            {Object.entries(selectedChampions).map(([role, champions]) => {
              // Get only enemy champions
              const enemyChampion = selectedSite === "Blue Side" ? champions[1] : champions[0];

              return (
                enemyChampion && championBuilds[enemyChampion] && (
                  <div key={role} style={{ margin: "10px", display: "flex" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                      src={championPhotos[championData[enemyChampion]]}
                      alt={`Champion ${enemyChampion}`}
                      style={{ width: "25px", height: "25px" }}
                      />

                      {/* Summoner Spell 1 */}
                      <img
                        src={summonerSpellDictionary[championBuilds[enemyChampion].summoner1Id]}
                        style={{ width: "25px", height: "25px" }}
                        alt="Summoner 1"
                      />
                      <input
                        style={{ height: "30px", textAlign: "center" }}
                        type="text"
                        placeholder="Cooldown (sec)"
                        value={summonerCooldowns[enemyChampion]?.summoner1 || ""}
                        onChange={(e) => handleCooldownChange(enemyChampion, "summoner1", e.target.value)}
                      />

                      <img
                        src={summonerSpellDictionary[championBuilds[enemyChampion].summoner2Id]}
                        style={{ width: "25px", height: "25px" }}
                        alt="Summoner 2"
                      />
                      <input
                        style={{ height: "30px", textAlign: "center" }}
                        type="text"
                        placeholder="Cooldown UP"
                        value={summonerCooldowns[enemyChampion]?.summoner2 || ""}
                        onChange={(e) => handleCooldownChange(enemyChampion, "summoner2", e.target.value)}
                      />
                    </div>
                  </div>
                )
              );
            })}
            <div>
              <button onClick={handleSubmitCooldowns}>Submit</button>
              <button onClick={handleNextTimer}>Next Timer</button>
            </div>


          </div>

        </div>
    </div>

  );
};

export default SummonerSpellTimerComponent;
